import axios from "axios";

export const getEnviosMavisos = async (apiKey) => {
  try {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PRODUCCION}/enviosMasivos/getEnviosMasivos`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const newEnvioMasivo = async (apiKey, data) => {
  try {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/enviosMasivos/newEnvioMasivo`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateEnvioMasivo = async (apiKey, id, data) => {
  try {
    let config = {
      method: "put",
      url: `${process.env.REACT_APP_API_PRODUCCION}/enviosMasivos/update/${id}`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteEnvioMasivo = async (apiKey, id) => {
  try {
    let config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_PRODUCCION}/enviosMasivos/delete/${id}`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateEnviosReturn = async (apiKey, id, data) => {
  let config = {
    method: "put",
    url: `${process.env.REACT_APP_API_PRODUCCION}/enviosMasivos/updateEnviosReturn/${id}`,
    headers: {
      Authorization: apiKey,
      "Content-Type": "application/json",
    },
    data: data,
  };

  const res = axios(config);

  return await res;
};

export const deleteEnviosReturn = async (apiKey, id) => {
  let config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_PRODUCCION}/enviosMasivos/deleteEnviosReturn/${id}`,
    headers: {
      Authorization: apiKey,
    },
  };

  const res = axios(config);

  return await res;
};

export const duplicateEnvio = async (apiKey, data) => {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_API_PRODUCCION}/enviosMasivos/duplicateEnvio/`,
    headers: {
      Authorization: apiKey,
    },
    data: data,
  };

  const res = axios(config);

  return await res;
};

export const getEnvioLogs = async (apiKey, id) => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API_PRODUCCION}/enviosMasivos/logs/${id}`,
    headers: {
      Authorization: apiKey,
    },
  };

  const res = axios(config);

  return await res;
};
