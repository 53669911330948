import { Button, Drawer, Form } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { getTemplates } from "../../../../../../helpers/getTemplates";
import { updateNode } from "../../../flows/utils/updateNode";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { HttpRequestVars } from "./HttpRequestVars";
import {
  CONTACT_VARIABLES,
  CONTEXT_VARIABLES,
} from "../../../flows/utils/contextVariables";
import { useUpdateNodeInternals } from "reactflow";
import { EnviosMasivosContext } from "../../../../../context/EnviosMasivosContext";
import { validateCharLimits } from "../../../campaigns/sendTemplates/utils/templateCharLimits";
import { validateTemplateVariables } from "../../../../../../utils/validateTemplateVariables";
import { TemplateSelector } from "../../../../template-form/TemplateSelector";
import { VariableTypes } from "../../../../template-form/VariableTypes";
import { TemplateHeader } from "../../../../template-form/TemplateHeader";
import {
  getButtonVars,
  getTemplatesOptions,
  getVariablesCount,
  removeButtonNodes,
  renderHTMLContent,
  validateXlsColumn,
  SECTIONS,
  handleTemplateVariables,
} from "../../../../template-form/utils";
import { TemplateBody } from "../../../../template-form/TemplateBody";
import { TemplateFooter } from "../../../../template-form/TemplateFooter";
import { TemplateButtons } from "../../../../template-form/TemplateButtons";

export const TemplateForm = () => {
  const { messageApi, instance } = useContext(GlobalContext);

  const { setMostrarDrawer, nodoSeleccionado, setNodes, setEdges } =
    useContext(EnviosMasivosContext);

  const [templates, setTemplates] = useState([]);
  const [loadingTemplates, setLoadingTemplates] = useState(true);

  const [templateSeleccionado, setTemplateSeleccionado] = useState({});
  const [templateVariables, setTemplateVariables] = useState({});
  const [url, setURL] = useState("");
  const [headerMediaType, setHeaderMediaType] = useState("image");
  const [buttonsVars, setButtonsVars] = useState([]);
  const [showHandles, setShowHandles] = useState(false);
  const [varsType, setVarsType] = useState("none");
  const [xlsData, setXlsData] = useState([]);
  const [xlsColumn, setXlsColumn] = useState(null);
  const [matchClientXls, setMatchClientXls] = useState(false);
  const [mostrarRequestDrawer, setMostrarRequestDrawer] = useState(false);
  const [urlParams, setUrlParams] = useState([]);

  const [requestResult, setRequestResult] = useState(null);

  const [marker, setMarker] = useState(null);

  const [viewport, setViewport] = useState({
    longitude: -63.244947,
    latitude: -32.410553,
    zoom: 15,
  });

  const [form] = Form.useForm();

  const updateNodeInternals = useUpdateNodeInternals();

  const onFinish = (values) => {
    let data = {};
    if (!templateSeleccionado.name) {
      return messageApi.info("Debe seleccionar un template");
    }

    let error = false;

    error = validateTemplateVariables(
      templateVariables,
      varOptions,
      messageApi
    );

    if (templateVariables.header?.type?.toUpperCase() !== "TEXT") {
      if (!url) {
        return messageApi.info("Ingrese una URL para el encabezado");
      }

      setTemplateVariables((prevState) => {
        let obj = { ...prevState };

        obj.header.value = { link: url };

        return obj;
      });
    }

    if (templateVariables.header?.type === "LOCATION") {
      if (!marker || marker?.longitude == null || marker?.latitude == null) {
        messageApi.info(
          "Seleccione un punto en el mapa o ingrese al menos latitud y longitud"
        );
        return;
      } else {
        setTemplateVariables((prevState) => {
          let obj = { ...prevState };

          obj.header.value = {
            ...marker,
            address: values.address || "",
            name: values.locationName || "",
          };

          return obj;
        });
      }
    }

    if (buttonsVars.length > 0) {
      if (!buttonsVars.every((element) => element.value)) {
        messageApi.info("Ingrese un valor para cada variable de botón");
        return;
      }
    }

    if (varsType === "xls") {
      if (!xlsData) {
        messageApi.info("Es necesario que ingrese un archivo excel valido");
        return;
      }

      if (!xlsColumn || !validateXlsColumn()) {
        messageApi.info(
          "Seleccione una columna con la cual relacionar el teléfono del contacto"
        );
        return;
      }

      if (validateCharLimits(templateVariables, xlsData, messageApi)) {
        messageApi.info("Modifique la información del excel antes de enviarlo");
        return;
      }

      data.xls = {
        column: xlsColumn,
        data: xlsData,
        matchClient: matchClientXls,
      };
    }

    if (varsType === "http" && requestResult) {
      data.httpRequest = requestResult;
    }

    if (!error) {
      data.templateVariables = { ...templateVariables, buttonsVars };
      data.template = templateSeleccionado;
      data.showHandles = showHandles;
      data.buttonsVars = buttonsVars;

      if (!showHandles) {
        removeButtonNodes(setNodes, setEdges, nodoSeleccionado?.id);
      }

      setNodes((prevState) => updateNode(nodoSeleccionado, data, prevState));
      setMostrarDrawer(false);
    }
  };

  useEffect(() => {
    const prevData = nodoSeleccionado?.data;

    handleTemplateVariables(
      templateSeleccionado,
      setHeaderMediaType,
      buttonsVars,
      setButtonsVars,
      prevData,
      setTemplateVariables
    );
  }, [templateSeleccionado]);

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      const { templateVariables, template, showHandles, xls, httpRequest } =
        nodoSeleccionado.data;

      if (templateVariables) {
        if (templateVariables.header?.type === "LOCATION") {
          const { value } = templateVariables.header;

          setViewport((prevState) => {
            return { ...prevState, ...value };
          });
          setMarker({
            latitude: value?.latitude || 0,
            longitude: value?.longitude || 0,
          });
          form.setFieldsValue({
            address: value?.address || "",
            locationName: value?.name || "",
          });
        }

        setTemplateVariables(templateVariables);
        if (templateVariables.header?.value) {
          const { link } = templateVariables.header.value;

          if (link && typeof link === "string") {
            // No modificar, si no toma link como un elemento nativo
            setURL(link);
          }
        }
      }

      if (template) {
        setTemplateSeleccionado(template);
      }

      if (templateVariables?.buttonsVars?.length > 0) {
        setButtonsVars(templateVariables?.buttonsVars);
      }

      if (showHandles) {
        setShowHandles(true);
      }

      if (xls) {
        setVarsType("xls");
        setXlsData(xls.data || []);
        setXlsColumn(xls.column);
        setMatchClientXls(xls.matchClient || false);
      }

      if (httpRequest) {
        // ACTUALIZAR TAMBIEN.
        setVarsType("http");
        setRequestResult(httpRequest);
      }
    }

    updateNodeInternals(nodoSeleccionado?.id);
  }, [nodoSeleccionado]);

  const xlsOptions = useMemo(() => {
    if (varsType !== "xls") {
      return [];
    }

    if (!xlsData || xlsData.length < 1) {
      return [];
    }

    let options = Object.keys(xlsData[0])?.map((element) => {
      return {
        value: element,
        label: element,
      };
    });

    return options;
  }, [xlsData, varsType]);

  const httpOptions = useMemo(() => {
    if (varsType !== "http") {
      return [];
    }

    if (!requestResult?.variable) {
      return [];
    }

    let options = [];

    const { innerVars, indexedVars } = requestResult.variable;

    if (innerVars?.length > 0) {
      options = innerVars?.map((element) => {
        return {
          value: element.name,
          label: element.name,
        };
      });
    }

    if (indexedVars?.length > 0) {
      options = indexedVars?.map((element) => {
        return {
          value: element.name,
          label: element.name,
        };
      });
    }

    return options;
  }, [requestResult, varsType]);

  const varOptions = useMemo(() => {
    let array = [
      ...CONTEXT_VARIABLES?.filter(
        (element) =>
          !["context_name", "context_phoneNumber"].includes(element.name)
      )?.map((element) => {
        return {
          value: element.name,
          label: element.label,
        };
      }),
      ...CONTACT_VARIABLES,
    ];

    if (xlsOptions) {
      array = [...array, ...xlsOptions];
    }

    if (httpOptions) {
      array = [...array, ...httpOptions];
    }

    return array;
  }, [httpOptions, xlsOptions]);

  useEffect(() => {
    renderHTMLContent(templateVariables);
  }, [templateVariables]);

  useEffect(() => {
    validateXlsColumn(xlsData, xlsColumn, messageApi, setXlsColumn);
  }, [xlsColumn, xlsData]);

  const templateOptions = useMemo(() => {
    if (templates?.length < 1) {
      return [];
    }

    return getTemplatesOptions(templates);
  }, [templates]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingTemplates(true);
      getTemplates(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200 && Array.isArray(res.data)) {
            setTemplates(res.data);
          } else {
            messageApi.error("Ocurrió un error al obtener plantillas");
          }
        })
        .catch((err) => {
          messageApi.error("Ocurrió un error al obtener plantillas");
        })
        .finally(() => setLoadingTemplates(false));
    }
  }, [instance]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="formText"
        requiredMark={false}
        onFinish={(v) => onFinish(v)}
        className="form-nodos"
      >
        <TemplateSelector
          templateOptions={templateOptions}
          templates={templates}
          setTemplateSeleccionado={setTemplateSeleccionado}
          templateSeleccionado={templateSeleccionado}
          loadingTemplates={loadingTemplates}
        />
        <VariableTypes
          templateSeleccionado={templateSeleccionado}
          setVarsType={setVarsType}
          varsType={varsType}
          setXlsData={setXlsData}
          xlsOptions={xlsOptions}
          matchClientXls={matchClientXls}
          setMatchClientXls={setMatchClientXls}
          xlsColumn={xlsColumn}
          setXlsColumn={setXlsColumn}
          setMostrarRequestDrawer={setMostrarRequestDrawer}
        />

        <TemplateHeader
          templateVariables={templateVariables}
          setTemplateVariables={setTemplateVariables}
          varOptions={varOptions}
          marker={marker}
          setMarker={setMarker}
          setViewport={setViewport}
          viewport={viewport}
          url={url}
          setURL={setURL}
          nodeId={null}
        />

        <TemplateBody
          templateVariables={templateVariables}
          setTemplateVariables={setTemplateVariables}
          varOptions={varOptions}
        />

        <TemplateFooter
          templateVariables={templateVariables}
          setTemplateVariables={setTemplateVariables}
          varOptions={varOptions}
        />

        <TemplateButtons
          templateVariables={templateVariables}
          setTemplateVariables={setTemplateVariables}
          varOptions={varOptions}
          showHandles={showHandles}
          setShowHandles={setShowHandles}
          buttonsVars={buttonsVars}
          setButtonsVars={setButtonsVars}
          context=""
        />

        <Drawer
          open={mostrarRequestDrawer}
          width={450}
          className="drawer-vars"
          push={{ distance: "450px" }}
          destroyOnClose
        >
          <HttpRequestVars
            setMostrarRequestDrawer={setMostrarRequestDrawer}
            nodeId={nodoSeleccionado?.id}
            setRequestResult={setRequestResult}
            nodeData={nodoSeleccionado?.data}
            urlParams={urlParams}
            setUrlParams={setUrlParams}
            modulo="envios"
          />
        </Drawer>
        <Form.Item className="form-custom-footer">
          <div className="botones-wrapper-content">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-guardar"
              size="large"
            >
              Guardar
            </Button>
            <Button
              className="btn-cancelar"
              size="large"
              type="secondary"
              onClick={() => {
                setMostrarDrawer(false);
              }}
            >
              Cancelar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
