import { Button, Modal } from 'antd';
import { useState } from 'react';
import { FaEye } from 'react-icons/fa6';

export const DocumentPreview = ({ src, record }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {record?.mimetype === 'application/pdf' ? (
        <Button
          icon={<FaEye />}
          onClick={() => setOpenModal(true)}
          shape='circle'
        ></Button>
      ) : (
        <></>
      )}

      <Modal
        className='preview-modal'
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
        closeIcon={null}
        destroyOnClose
      >
        <span>
          <iframe
            className='image-preview'
            title='pdf-preview'
            src={src}
            width={1024}
            height={700}
          />
        </span>
      </Modal>
    </>
  );
};
