import { useContext, useEffect } from "react";
import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { FlowContext } from "../../../../context/FlowContext";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { RiGitBranchLine } from "react-icons/ri";
import { TbTrash } from "react-icons/tb";
import { useBorrarNode } from "../utils/useBorrarNode";
import { Tag, Popover } from "antd";
import { checkValidConnection, handleOperator, operadores } from "../utils/utilidadesFlow";
import { getNodeName } from "../utils/getNodeName";
import { GlobalContext } from "../../../../context/GlobalContext";
import { PiAddressBookBold } from "react-icons/pi"; // PiAddressBookBold icono contactos brocoly
import { FiTag } from "react-icons/fi";
import { Tag as CustomTag } from "../../../tag/Tag";

export const TagBranchNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const { contactos, etiquetas } = useContext(GlobalContext);

  const updateNodeInternals = useUpdateNodeInternals();

  const borrarNode = useBorrarNode(node.id);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  useEffect(() => {
    updateNodeInternals(node.id);
  }, [node]);


  // Obtener los nombres de los contactos que coinciden por _id
  const nombresDeContactos = node.data.contacts.map(idSeleccionado => {
    const contactoEncontrado = contactos.find(contacto => contacto._id === idSeleccionado);
    return contactoEncontrado ? `${contactoEncontrado.name.first_name} ${contactoEncontrado.name.middle_name} ${contactoEncontrado.name.last_name}`.trim() : null;
  }).filter(nombre => nombre !== null); // Filtrar para eliminar posibles null

  const content = (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {nombresDeContactos.map((name, index) => (
      <span
        key={index}
        style={{ marginRight: index !== nombresDeContactos.length - 1 ? "4px" : 0 }}
      >
        <Tag icon={<PiAddressBookBold />} color="orange" className="tags-icon-align" >{name} </Tag>
      </span>
      ))}
    </div>
  );


  const tags = node?.data?.cases.map(item => {
    const matchedItems = etiquetas.filter(obj => item.value && item.value.includes(obj._id));
    // return {
    //   id: item.id,
    //   childId: item.childId,
    //   operator: item.operator,
    //   value: matchedItems
    // };

    return matchedItems.length > 0
      ? {
        id: item.id,
        childId: item.childId,
        operator: item.operator,
        value: matchedItems
      }
      : {
        id: item.id,
        childId: item.childId,
        operator: item.operator
      };
  });

  // console.log('node?.data?.cases: ', node?.data?.cases)
  // console.log('tags: ', tags);



  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setMostrarDrawer(true);
        setNodoSeleccionado(node);
      }}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header">
        <RiGitBranchLine size={32} />
        <div className="node-description">
          <span className="node-type">{getNodeName(node)}</span>
          <span className="mensaje-nodo">{node.data?.label}</span>
        </div>
      </div>
      <Popover placement="top" content={content}>
        <Tag icon={<PiAddressBookBold />} color="orange" className="tags-icon-align">
          Contactos
        </Tag>
      </Popover>
      <div className="branch-conditions-wrapper">
        {tags?.map((caso, index) => {
          if (caso.value) {
            return (
              <span className="case-display" key={index}>
                <div className="horizontal" style={{ gap: "4px" }}>
                  <p>{handleOperator(caso.operator?.[0])}</p>
                  <Popover
                    placement="top"
                    content={
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {caso.value.map((tag, index) => (
                          <span
                            key={index}
                            style={{ marginRight: index !== tags.length - 1 ? "4px" : 0 }}
                          >
                            <CustomTag hex={tag.color} nombre={tag.name} key={tag._id} />
                          </span>
                        ))}
                      </div>
                    }
                  >
                    <Tag icon={<FiTag />} color="green" className="tags-icon-align">
                      Etiquetas
                    </Tag>
                  </Popover>
                </div>
                <span className="linea-cases"></span>
                <Handle
                  type="source"
                  position={Position.Right}
                  id={`${caso.id}`}
                  isConnectable={checkValidConnection(
                    edges,
                    node.id,
                    `${caso.id}`
                  )}
                />
              </span>
            );
          } else {
            <></>;
          }
        })}
        <span className="case-display case-default">
          <span className="linea-cases"></span>
          <p>Otro...</p>
          <Handle
            className=""
            type="source"
            position={Position.Right}
            id="default"
            isConnectable={checkValidConnection(edges, node.id, "default")}
          />
        </span>
      </div>
      <div className="path-id">{node?.id}</div>
    </div>
  );
};
