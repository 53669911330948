import { useNavigate } from 'react-router-dom';
import { ImWhatsapp } from 'react-icons/im';
import { Tag } from 'antd';
import { CardBotActions } from './CardBotActions';
import { getCompletedPercentage } from '../utils/getCompletedPercentage';
import { CardBotQRPreview } from './CardBotQRPreview';

export const CardBot = ({
  flow,
  setFlows,
  setLoading,
  qrCodes,
  setQrCodes
}) => {
  const { name, description, status, trigger } = flow;

  const navigate = useNavigate();

  function statusColor(status) {
    if (status) {
      switch (status) {
        case 'draft':
          return '';
        case 'publish':
          return 'green';
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  const qrCode = qrCodes?.find((qr) => qr.flowId === flow._id);

  return (
    <div className='CardBot' key={flow?._id}>
      <div className='card-bot-first-container'>
        <div className='card-bot-first-row'>
          <div
            className='horizontal gap8'
            onClick={() => navigate(`/flows/edit?id=${flow?._id}`)}
            style={{ cursor: 'pointer' }}
          >
            <ImWhatsapp style={{ color: 'var(--whatsapp-color)' }} size={20} />
            <span className='card-bot-name'>{name}</span>
          </div>

          <span className='fila-triggers' style={{ flexWrap: 'nowrap' }}>
            <Tag color={statusColor(status)}>
              {status === 'publish' ? 'ACTIVO' : 'BORRADOR'}
            </Tag>

            {qrCode && (
              <CardBotQRPreview
                flow={flow}
                qrCode={qrCode}
                setQrCodes={setQrCodes}
              />
            )}
          </span>
        </div>

        <p className='card-bot-description'>{description}</p>
        <div className='card-bot-row' style={{ alignItems: 'flex-start' }}>
          <span className='card-bot-label'>Disparador:</span>
          {flow?.type === 'broadcast' ? (
            <Tag color='blue'>Campaña</Tag>
          ) : (
            <>
              <span className='fila-triggers'>
                {trigger.map((text, index) => (
                  <Tag color='gold' key={index}>
                    {text}
                  </Tag>
                ))}
              </span>
            </>
          )}
        </div>

        <div className='card-bot-row'>
          <span className='card-bot-label'>Ejecuciones:</span>
          <span>{flow?.executionCount}</span>
        </div>

        <div className='card-bot-row'>
          <span className='card-bot-label'>% de éxito:</span>
          <span>
            {getCompletedPercentage(flow)}% ({flow?.completedCount})
          </span>
        </div>
      </div>

      <div className='card-bot-data-container'>
        <CardBotActions
          flow={flow}
          setFlows={setFlows}
          setLoading={setLoading}
        />
      </div>
    </div>
  );
};
