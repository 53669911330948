import { Button, Table, Tabs, Tooltip } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { FiPlus } from 'react-icons/fi';
import { MdOutlineBlock } from 'react-icons/md';
import './contactos.css';
import { getContactName } from '../campaigns/sendTemplates/utils/getContactName';
import { useAccess } from '../../../../hooks/useAccess';
import { accessDataFormat } from '../../../../helpers/access';
import { AccionesContactos } from './AccionesContactos';
import { getBlacklist } from '../../../../helpers/fetchData/fetchBlacklist';
import { getVisibleColumns } from '../../../../helpers/fetchData/fetchVisibleColumns';
import LoadingIcon from '../../Loader/LoadingIcon';
import { formatPhoneNumber } from '../rightDrawer/utils/formatPhoneNumber';
import { Buscador } from '../../buscadores/Buscador';
import { EtiquetasXContactos } from './EtiquetasXContactos';
import { useLocation, useNavigate } from 'react-router-dom';
import { TablaContactsLeads } from './leads/TablaContactsLeads';

export const Contactos = ({ tab }) => {
  const {
    contactos,
    setContactos,
    setSideDrawerConfig,
    instance,
    messageApi,
    loadingContactos
  } = useContext(GlobalContext);

  const navigate = useNavigate();

  const location = useLocation();

  const [unarchivedContactos, setUnarchivedContactos] = useState([]);
  const [filteredContactos, setFilteredContactos] = useState([]);
  const [bloqueados, setBloqueados] = useState([]);
  const [loadingVisibleColumns, setLoadingVisibleColumns] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([]);

  const [activeTab, setActiveTab] = useState(tab || 'activos');

  const isAllowed = useAccess();

  useEffect(() => {
    const contactosUnarchived = contactos.filter(
      (contacto) => !contacto.isArchived
    );

    // Se necesitan agregar las keys 'fullName', 'email' y 'phoneNumber' para hacer búsquedas:
    const contactosWithKeys = contactosUnarchived.map((contacto) => ({
      ...contacto,
      fullName: getContactName(contacto.name),
      email: contacto.emails[0].email,
      phoneNumber: contacto.phones[0].phone
    }));

    setUnarchivedContactos(contactosWithKeys);
  }, [contactos]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getBlacklist(instance, messageApi, setBloqueados);
    }
  }, [instance]);

  useEffect(() => {
    const newContactos = contactos.map((contacto) => {
      const isBlocked = bloqueados.some((bloqueado) => {
        return bloqueado.number === contacto.phones?.[0]?.phone;
      });
      return {
        ...contacto,
        isBlocked: isBlocked
      };
    });
    setContactos(newContactos);
  }, [bloqueados]);

  useEffect(() => {
    if (instance) {
      getVisibleColumns(
        instance,
        messageApi,
        setVisibleColumns,
        setLoadingVisibleColumns
      );
    }
  }, [instance]);

  useEffect(() => {
    setFilteredContactos([...unarchivedContactos]);
  }, [unarchivedContactos]);

  const columns = [
    {
      title: 'Nombre completo',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      fixed: 'left',
      render: (data, record) => {
        return (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 5,
              textDecoration: record.isBlocked ? 'line-through' : 'none'
            }}
          >
            {record.isBlocked && (
              <Tooltip title='Este contacto ha sido bloqueado'>
                <span>
                  <MdOutlineBlock style={{ fontSize: 18, color: 'red' }} />
                </span>
              </Tooltip>
            )}
            {getContactName(data)}
          </span>
        );
      }
    },
    {
      title: 'Email',
      dataIndex: 'emails',
      key: 'emails',
      ellipsis: true,
      render: (data) => data?.[0]?.email
    },
    {
      title: 'Teléfono',
      dataIndex: 'phones',
      key: 'phones',
      width: 150,
      render: (data) => formatPhoneNumber(data?.[0]?.phone)
    },
    {
      title: 'Etiquetas',
      dataIndex: 'tags',
      key: 'tags',
      render: (data) => {
        if (!data) return <></>;
        return <EtiquetasXContactos data={data} />;
      }
    }
  ];

  // Campos adicionales
  if (visibleColumns) {
    visibleColumns.forEach((field) => {
      if (field.isVisible) {
        columns.push({
          title: field.label,
          key: field.key,
          dataIndex: field.key,
          ellipsis: true,
          align: 'center'
        });
      }
    });
  }

  columns.push({
    title: '...',
    dataIndex: '',
    align: 'center',
    key: '',
    fixed: 'right',
    render: (dataIndex, item) => <AccionesContactos item={item} />
  });

  useEffect(() => {
    setActiveTab(tab);
  }, [location, tab]);

  return (
    <div className='main-wrapper'>
      <div className='main-header'>
        <span>Contactos</span>

        <Buscador
          element='contacto'
          data={unarchivedContactos}
          setFilteredData={setFilteredContactos}
          searchKeys={['fullName', 'phoneNumber', 'email']}
        />

        <div className='espacio-botones-contactos'>
          {isAllowed(accessDataFormat('contacts', 'manage')) && (
            <Button
              type='primary'
              className='btn-agregar'
              onClick={() =>
                setSideDrawerConfig({
                  visible: true,
                  content: 'plus',
                  tab: 'contact'
                })
              }
            >
              <FiPlus /> Contacto
            </Button>
          )}
        </div>
      </div>

      <Tabs
        style={{ marginInline: '7.5%' }}
        destroyInactiveTabPane
        activeKey={activeTab}
        onChange={(v) => {
          navigate(`/contactos/${v}`);
          // setActiveTab(v);
        }}
        items={[
          {
            label: 'Activos',
            key: 'activos',
            children: (
              <Table
                columns={columns}
                dataSource={filteredContactos || []}
                rowKey='_id'
                size='small'
                pagination={{
                  pageSize: 10,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} de ${total} contactos`
                }}
                tableLayout='fixed'
                // className="tabla-contactos"
                loading={{
                  spinning: loadingContactos,
                  indicator: <LoadingIcon size='large' />
                }}
                scroll={{ x: 'max-content' }}
              />
            )
          },
          {
            label: 'Leads',
            key: 'leads',
            children: <TablaContactsLeads />
          }
        ]}
      />
    </div>
  );
};
