import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import { FiActivity, FiShoppingCart, FiUsers } from "react-icons/fi";
import { Tooltip } from "antd";
import { useLocation } from "react-router-dom";
import { LiaRobotSolid } from "react-icons/lia";
import { TbMessage } from "react-icons/tb";
import { PiAddressBookBold } from "react-icons/pi";
import { IoMegaphoneOutline } from "react-icons/io5";
import { useAccess } from "../../../../hooks/useAccess";
import { accessDataFormat } from "../../../../helpers/access";
import { useIsDevsInstance } from "../../../../hooks/useIsDevsInstance";

const ModuleMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { sideDrawerConfig, setSideDrawerConfig } = useContext(GlobalContext);

  const isAllowed = useAccess();

  const { isDevsInstance } = useIsDevsInstance();

  const handleChange = (route) => {
    navigate(route);
    setSideDrawerConfig({ visible: false, content: "" });
  };

  const isActive = (route) => {
    if (Array.isArray(route)) {
      return (
        route.some((element) => location.pathname.includes(element)) &&
        !location.pathname.includes("settings") &&
        !sideDrawerConfig.visible
      );
    } else {
      return (
        location.pathname.includes(route) &&
        !location.pathname.includes("settings") &&
        !sideDrawerConfig.visible
      );
    }
  };

  return (
    <div className="menu-modulos">
      <Tooltip placement="right" title={"Dashboard"} mouseEnterDelay={0.3}>
        <div
          className={isActive("dashboard") ? "item-menu-selected" : "item-menu"}
          onClick={() => handleChange("/dashboard")}
        >
          <FiActivity
            className={
              isActive("dashboard") ? "icon-menu-selected" : "icon-menu"
            }
          />
        </div>
      </Tooltip>

      {isAllowed(accessDataFormat("contacts", "show")) && (
        <Tooltip placement="right" title={"Contactos"} mouseEnterDelay={0.3}>
          <div
            className={
              isActive("contactos") ? "item-menu-selected" : "item-menu"
            }
            onClick={() => handleChange("/contactos/activos")}
          >
            <PiAddressBookBold
              className={
                isActive("contactos") ? "icon-menu-selected" : "icon-menu"
              }
            />
          </div>
        </Tooltip>
      )}
      {isAllowed(accessDataFormat("clients", "show")) && (
        <Tooltip placement="right" title={"Clientes"} mouseEnterDelay={0.3}>
          <div
            className={
              isActive("clientes") ? "item-menu-selected" : "item-menu"
            }
            onClick={() => handleChange("/clients")}
          >
            <FiUsers
              className={
                isActive("clientes") ? "icon-menu-selected" : "icon-menu"
              }
            />
          </div>
        </Tooltip>
      )}
      {isAllowed(accessDataFormat("bots", "show")) && (
        <Tooltip placement="right" title={"Bots"} mouseEnterDelay={0.3}>
          <div
            className={isActive("flows") ? "item-menu-selected" : "item-menu"}
            onClick={() => handleChange("/flows")}
          >
            <LiaRobotSolid
              className={isActive("flows") ? "icon-menu-selected" : "icon-menu"}
            />
          </div>
        </Tooltip>
      )}

      {isAllowed(accessDataFormat("broadcast", "show")) && (
        <Tooltip placement="right" title={"Mensajes"} mouseEnterDelay={0.3}>
          <div
            className={
              isActive(["mensajes", "campaigns", "envios"])
                ? "item-menu-selected"
                : "item-menu"
            }
            onClick={() => handleChange("/mensajes")}
          >
            <IoMegaphoneOutline
              size={22}
              className={
                isActive(["mensajes", "campaigns", "envios"])
                  ? "icon-menu-selected"
                  : "icon-menu"
              }
            />
          </div>
        </Tooltip>
      )}
      {/* Mostrar solo en binamics */}
      {isDevsInstance && (
        <>
          {isAllowed(accessDataFormat("chat", "show")) && (
            <Tooltip placement="right" title={"Chat"} mouseEnterDelay={0.3}>
              <div
                className={
                  isActive("chat") ? "item-menu-selected" : "item-menu"
                }
                onClick={() => handleChange("/chat")}
              >
                <TbMessage
                  className={
                    isActive("chat") ? "icon-menu-selected" : "icon-menu"
                  }
                />
              </div>
            </Tooltip>
          )}

          {isAllowed(accessDataFormat("budgets", "show")) && (
            <Tooltip
              placement="right"
              title={"Presupuestos"}
              mouseEnterDelay={0.3}
            >
              <div
                className={
                  isActive("presupuestos") ? "item-menu-selected" : "item-menu"
                }
                onClick={() => handleChange("/presupuestos")}
              >
                <FiShoppingCart
                  size={22}
                  className={
                    isActive("presupuestos")
                      ? "icon-menu-selected"
                      : "icon-menu"
                  }
                />
              </div>
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

export default ModuleMenu;
