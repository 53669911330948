export const statusOptions = [
  { value: "sent", label: "Enviados" },
  { value: "delivered", label: "Entregados" },
  { value: "read", label: "Leídos" },
];

export const getPorcentaje = (category, resumen, inner) => {
  let value = resumen[category] || 0;

  let porcentaje = Math.round((value / resumen?.contacts || 0) * 100);

  if (inner) {
    return porcentaje + "%";
  }

  return `(${Math.round((value / resumen?.contacts || 0) * 100)}%)`;
};

export const statsProps = {
  valueStyle: { fontSize: 32, fontWeight: 600, lineHeight: "32px" },
  groupSeparator: ".",
  decimalSeparator: ",",
};

export const innerStatsProps = {
  valueStyle: { fontSize: 16, fontWeight: 600, lineHeight: "16px" },
  groupSeparator: ".",
  decimalSeparator: ",",
};

export const datePickerOptions = [
  { value: "date", label: "Día" },
  { value: "week", label: "Semana" },
  { value: "month", label: "Mes" },
];

export const datePickerFormat = {
  hour: "HH A",
  date: "DD/MM/YYYY",
  week: "wo/YYYY",
  month: "MM/YYYY",
};

export function getPorcentajePrueba(dataIndex, data, color) {
  const index = data.findIndex((i) => i.name === dataIndex);

  if (index !== -1) {
    const value = data[index].value;
    const total = data.reduce((acc, i) => acc + i.value, 0);

    return (
      <span style={{ color: color }}>{`(${((value / total || 0) * 100).toFixed(
        2
      )}%)`}</span>
    );
  }
}
