import { useContext, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { Button, Form, Tabs, Tooltip } from "antd";
import LoadingIcon from "../Loader/LoadingIcon";
import { AiOutlineUser } from "react-icons/ai";
import { FiTag, FiUsers } from "react-icons/fi";
import { GeneralInfo } from "./contactEditor/GeneralInfo";
import { TagContact } from "./contactEditor/TagContact";
import { SelectClients } from "./contactEditor/SelectClients";
import { ContactAdditionalFields } from "./contactEditor/ContactAdditionalFields";
import { RiPlayListAddLine } from "react-icons/ri";
import { FormErrorsNotification } from "./contactEditor/FormErrorsNotification";
import dayjs from "dayjs";
import {
  associateContactToClients,
  editContact,
  newContact,
} from "../../../helpers/fetchData/fetchContacts";
import { formatContactData } from "./utils/formatContactData";

export const ContactEditor = ({ editMode }) => {
  const {
    rightDrawerConfig,
    setRightDrawerConfig,
    setSideDrawerConfig,
    notificationApi,
    instance,
    messageApi,
    setContactos,
  } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  const [editContactForm] = Form.useForm();
  const [editGeneralInfoForm] = Form.useForm();
  const [contactTagsForm] = Form.useForm();
  const [editAdditionalFieldsForm] = Form.useForm();
  const [selectClientsForm] = Form.useForm();

  const { item } = rightDrawerConfig;

  const fetchAssociateClients = async (data) => {
    associateContactToClients(instance?.userData?.apiKey, data)
      .then()
      .catch((err) => {
        messageApi.error(
          "Hubo un error al asociar el contacto con los clientes"
        );
        console.error(err);
      });
  };

  const handleSave = async () => {
    const { isValid, errorFields, values } = await validateForms();

    if (!isValid) {
      notificationApi.error({
        placement: "top",
        message: "Formulario no válidado",
        description: <FormErrorsNotification errorFields={errorFields} />,
        duration: 10,
      });
    } else {
      let formattedValues = formatContactData(values);

      let { clients, ...valuesWithoutClients } = formattedValues;

      if (editMode) {
        valuesWithoutClients = { ...valuesWithoutClients, _id: item._id };

        editContact(instance, valuesWithoutClients)
          .then((res) => {
            if (res?.status === 200) {
              messageApi.success("Contacto editado correctamente");

              setContactos((prevState) => {
                let array = [...prevState];

                const index = array.findIndex(
                  (contacto) => contacto._id === item._id
                );

                if (index >= 0) {
                  array[index] = { ...array[index], ...valuesWithoutClients };
                }

                return array;
              });

              const associateClientsData = {
                contactId: item._id,
                clientsIds: clients,
              };

              fetchAssociateClients(associateClientsData);
            } else {
              messageApi.error("Ocurrió un error al editar el contacto");
            }
          })
          .catch((err) => {
            console.error(err);
            messageApi.error("Ocurrió un error al editar el contacto");
          })
          .finally(() => setLoading(false));
      } else {
        await newContact(instance, valuesWithoutClients)
          .then((res) => {
            if (res?.status === 201) {
              messageApi.success("Contacto creado correctamente");

              setContactos((prevState) => {
                let newArray = [...prevState];

                const nuevoContacto = {
                  _id: res.data?._id,
                  ...valuesWithoutClients,
                };

                newArray.push(nuevoContacto);

                return newArray;
              });

              const associateClientsData = {
                contactId: res.data?._id,
                clientsIds: clients,
              };

              fetchAssociateClients(associateClientsData);
            } else {
              messageApi.error("Ocurrió un error al crear el contacto");
            }
          })
          .catch((err) => {
            console.error(err);
            messageApi.error("Ocurrió un error al crear el contacto");
          })
          .finally(() => setLoading(false));
      }

      handleClose();
    }
  };

  const validateForms = async () => {
    let isValid = true;
    let errorFields = [];
    let values = {};

    await Promise.all([
      validateOneForm(editGeneralInfoForm, "Información General"),
      validateOneForm(contactTagsForm, "Etiquetas"),
      validateOneForm(editAdditionalFieldsForm, "Campos adicionales"),
      validateOneForm(selectClientsForm, "Clientes"),
    ]).then((results) => {
      results.forEach((result) => {
        if (!result.isValid) {
          isValid = false;
          errorFields.push({
            formName: result.formName,
            errors: result.errorFields,
          });
        }
        values = { ...values, ...result.values };
      });
    });

    return {
      isValid,
      errorFields,
      values,
    };
  };

  const validateOneForm = (form, formName) => {
    return form
      .validateFields()
      .then((values) => {
        return { formName, isValid: true, values, errorFields: [] };
      })
      .catch((err) => {
        return {
          formName,
          isValid: false,
          values: {},
          errorFields: err.errorFields,
        };
      });
  };

  const tabsItems = [
    {
      key: "generalInfo",
      icon: (
        <Tooltip title="Información general">
          <span>
            <AiOutlineUser size={20} />
          </span>
        </Tooltip>
      ),
      children: <GeneralInfo editMode={editMode} form={editGeneralInfoForm} />,
      forceRender: true,
    },
    {
      key: "additionalFields",
      icon: (
        <Tooltip title="Campos adicionales">
          <span>
            <RiPlayListAddLine size={20} />
          </span>
        </Tooltip>
      ),
      children: (
        <ContactAdditionalFields
          editMode={editMode}
          form={editAdditionalFieldsForm}
        />
      ),
      forceRender: true,
    },
    {
      key: "tags",
      icon: (
        <Tooltip title="Etiquetas">
          <span>

          <FiTag size={20} />
          </span>
        </Tooltip>
      ),
      children: <TagContact editMode={editMode} form={contactTagsForm} />,
      forceRender: true,
    },
    {
      key: "clients",
      icon: (
        <Tooltip title="Clientes">
          <span>
          <FiUsers size={20} />
            </span>
        </Tooltip>
      ),
      children: <SelectClients editMode={editMode} form={selectClientsForm} />,
      forceRender: true,
    },
  ];

  const handleClose = () => {
    editMode
      ? setRightDrawerConfig({
          visible: false,
          content: "",
        })
      : setSideDrawerConfig({
          visible: false,
          content: "",
        });
  };

  return (
    <Form
      form={editContactForm}
      name="editContact"
      className="form-nodos"
      style={{ overflow: "hidden", padding: !editMode ? 24 : 0 }}
      layout="vertical"
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>
        {editMode ? "Editar" : "Añadir"} contacto
      </p>
      <Tabs items={tabsItems} centered />

      <Form.Item className="form-custom-footer form-contact-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            className="btn-guardar"
            size="large"
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
            onClick={handleSave}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
