import { BranchNode } from './customNodes/BranchNode';
import { BudgetNode } from './customNodes/BudgetNode';
import { ButtonNode } from './customNodes/ButtonNode';
import { DefaultNode } from './customNodes/DefaultNode';
import { EndNode } from './customNodes/EndNode';
import { HttpRequestNode } from './customNodes/HttpRequestNode';
import { IterableListNode } from './customNodes/IterableListNode';
import { ListNode } from './customNodes/ListNode';
import { MessageNode } from './customNodes/MessageNode';
import { MultiProductNode } from './customNodes/MultiProductNode';
import { OneTimePasswordNode } from './customNodes/OneTimePasswordNode';
import { SaveResponseNode } from './customNodes/SaveResponseNode';
import { SendTemplateNode } from './customNodes/SendTemplateNode';
import { SetDelayNode } from './customNodes/SetDelayNode';
import { SetVariablesNode } from './customNodes/SetVariablesNode';
import { SingleProductNode } from './customNodes/SingleProductNode';
import { TimeNode } from './customNodes/TimeNode';
import { TranscribeAudioNode } from './customNodes/TranscribeAudioNode';
import { TriggerNode } from './customNodes/TriggerNode';
import { WorkingNode } from './customNodes/WorkingNode';
import { EncodeNode } from './customNodes/EncodeNode';
import { CtaButtonNode } from './customNodes/CtaButtonNode';
import { DecodeNode } from './customNodes/DecodeNode';
import { CallFunctionNode } from './customNodes/CallFunctionNode';
import { BroadcastNode } from './customNodes/BroadcastNode';
import { ContactAgentNode } from './customNodes/ContactAgentNode';
import { IsContactNode } from './customNodes/IsContactNode';
import { FormulaNode } from './customNodes/FormulaNode';
import { SendMessageToChatNode } from './customNodes/SendMessageToChatNode';
import { TagContactNode } from './customNodes/TagContactNode';
import { SubscriptionNode } from './customNodes/SubscriptionNode';
import { GoToFlowNode } from './customNodes/GoToFlowNode';
import { SendEmailNode } from './customNodes/SendEmailNode';
import { NoteNode } from './customNodes/NoteNode';
import { ClientsByContactNode } from './customNodes/ClientsByContactNode';
import { TagBranchNode } from './customNodes/TagBranchNode';

export const nodeTypes = {
  trigger: TriggerNode,
  message: MessageNode,
  buttons: ButtonNode,
  list: ListNode,
  inicial: DefaultNode,
  end: EndNode,
  branch: BranchNode,
  tagBranch: TagBranchNode,
  dateTime: TimeNode,
  transcribeAudio: TranscribeAudioNode,
  httpRequest: HttpRequestNode,
  setDelay: SetDelayNode,
  saveResponse: SaveResponseNode,
  sendTemplate: SendTemplateNode,
  iterableList: IterableListNode,
  setVariables: SetVariablesNode,
  // textToSpeech: TextToSpeechNode,
  workingNode: WorkingNode,
  oneTimePassword: OneTimePasswordNode,
  sendMultiProduct: MultiProductNode,
  sendSingleProduct: SingleProductNode,
  budget: BudgetNode,
  encode: EncodeNode,
  cta_button: CtaButtonNode,
  decode: DecodeNode,
  callFunction: CallFunctionNode,
  broadcast: BroadcastNode,
  contactAgent: ContactAgentNode,
  isContact: IsContactNode,
  formula: FormulaNode,
  sendMessageToChat: SendMessageToChatNode,
  tagContact: TagContactNode,
  subscription: SubscriptionNode,
  goToFlow: GoToFlowNode,
  sendEmail: SendEmailNode,
  note: NoteNode,
  clientsByContact: ClientsByContactNode
};

export const NODE_TYPES = [
  { id: 'note', name: 'Nota', category: 'Otros', description: '' },
  {
    id: 'message',
    name: 'Enviar mensaje',
    category: 'Mensajes',
    description: 'Envía un mensaje básico'
  },
  {
    id: 'buttons',
    name: 'Mensaje con botones',
    category: 'Mensajes',
    description: ''
  },
  {
    id: 'list',
    name: 'Mensaje con lista',
    category: 'Mensajes',
    description: ''
  },
  {
    id: 'cta_button',
    name: 'Botón URL',
    category: 'Mensajes',
    description: ''
  },
  { id: 'branch', name: 'Ramas', category: 'Flujo', description: '' },
  { id: 'tagBranch', name: 'Ramas de etiquetas', category: 'Flujo', description: '' },
  { id: 'budget', name: 'Presupuesto', category: 'Otros', description: '' },
  { id: 'dateTime', name: 'Horarios', category: 'Flujo', description: '' },
  {
    id: 'transcribeAudio',
    name: 'Transcribir audio',
    category: 'IA',
    description: ''
  },
  {
    id: 'httpRequest',
    name: 'Petición HTTP',
    category: 'Datos y variables',
    description: ''
  },
  {
    id: 'iterableList',
    name: 'Lista iterable',
    category: 'Datos y variables',
    description: ''
  },
  { id: 'setDelay', name: 'Demora', category: 'Flujo', description: '' },
  {
    id: 'sendTemplate',
    name: 'Enviar template',
    category: 'Mensajes',
    description: ''
  },
  {
    id: 'tagContact',
    name: 'Etiquetar contacto',
    category: 'Contactos',
    description: 'Asigna una etiqueta al contacto actual'
  },

  {
    id: 'clientsByContact',
    name: 'Seleccionar cuentas del contacto',
    category: 'Contactos'
  },
  {
    id: 'setVariables',
    name: 'Set variables',
    category: 'Datos y variables',
    description: 'Crea variables para utilizar en el flujo de este bot'
  },
  // { id: "sendSingleProduct", name: "Producto único" },
  // { id: "sendMultiProduct", name: "Multi-producto" },
  {
    id: 'oneTimePassword',
    name: 'Contraseña de un solo uso',
    category: 'Seguridad',
    description: ''
  },
  { id: 'encode', name: 'Codificar', category: 'Seguridad', description: '' },
  { id: 'decode', name: 'Decodificar', category: 'Seguridad', description: '' },
  {
    id: 'callFunction',
    name: 'Interacción única ChatGPT',
    category: 'IA',
    description: ''
  },
  {
    id: 'isContact',
    name: '¿Es contacto?',
    category: 'Contactos',
    description:
      'Verifica si la persona con la que está comunicándote es un contacto registrado'
  },
  {
    id: 'formula',
    name: 'Convertir',
    category: 'Datos y variables',
    description: ''
  },
  // { id: "contactAgent", name: "Contactar agente" },
  // { id: "sendMessageToChat", name: "Guardar mensaje en chat" },
  {
    id: 'subscription',
    name: 'Suscribir contacto',
    category: 'Contactos',
    description: ''
  },
  {
    id: 'goToFlow',
    name: 'Redirigir a otro bot',
    category: 'Flujo',
    description: ''
  },
  {
    id: 'sendEmail',
    name: 'Enviar correo electrónico',
    category: 'Otros',
    description: ''
  },
  { id: 'end', name: 'Fin de flujo', category: 'Flujo', description: '' }
  // { id: "textToSpeech", name: "Texto a audio" },
];
