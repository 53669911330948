import { TbTrash } from "react-icons/tb";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { Handle, Position } from "reactflow";
import { FiUsers } from "react-icons/fi";
import { getNodeName } from "../utils/getNodeName";
import { useContext } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { useBorrarNode } from "../utils/useBorrarNode";
import { checkValidConnection } from "../utils/utilidadesFlow";

export const ClientsByContactNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  const borrarNode = useBorrarNode(node.id);

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setNodoSeleccionado(node);
        setMostrarDrawer(true);
      }}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header">
        <div style={{ height: "32px" }}>
          <FiUsers color="var(--dark-color)" size={32} />
        </div>
        <div className="node-description">
          <span className="node-type">{getNodeName(node)}</span>
          <span className="mensaje-nodo">{node.data.label}</span>
        </div>
      </div>
      <span className="contenedor-handles">
        <span className="linea-handle">
          <p>Next step</p>
          <Handle
            type="source"
            className="custom-handle next-step"
            position={Position.Right}
            id="nextStep"
            isConnectable={checkValidConnection(edges, node.id, "nextStep")}
          />
        </span>
        <span className="linea-handle">
          <p>No tiene cuentas</p>
          <Handle
            type="source"
            className="custom-handle fallback"
            position={Position.Right}
            id="fallback"
            isConnectable={checkValidConnection(edges, node.id, "fallback")}
          />
        </span>
      </span>
      <div className="path-id" style={{ marginTop: "30px" }}>
        {node?.id}
      </div>
    </div>
  );
};
