import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import EntryView from '../views/EntryView';
import DashboardView from '../views/DashboardView';
import { ContactoView } from '../views/ContactoView';
import { FlowsView } from '../views/FlowsView';
import { EditFlowView } from '../views/EditFlowView';
import { ChatView } from '../views/ChatView';
import { ConfigView } from '../views/ConfigView';
import CreateTemplate from '../ui/Layout/settings/content/templates/CreateTemplate';
import { Templates } from '../ui/Layout/settings/content/templates/Templates';
import { Catalog } from '../ui/Layout/settings/content/catalog/Catalog';
import { Tags } from '../ui/Layout/settings/content/Tags';
import { Users } from '../ui/Layout/settings/content/users/Users';
import { CanalesContent } from '../ui/Layout/settings/content/channels/CanalesContent';
import { Teams } from '../ui/Layout/settings/content/teams/Teams';
import { Integrations } from '../ui/Layout/settings/content/integrations/Integrations';
import { PresupuestoView } from '../views/PresupuestoView';
import { EditTemplate } from '../ui/Layout/settings/content/templates/EditTemplate';
import { ContactsSettings } from '../ui/Layout/settings/content/contacts/ContactsSettings';
import { InitialView } from '../views/InitialView';
import { SendTemplateView } from '../views/SendTemplateView';
import { CampaignsView } from '../views/CampaignsView';
// import { CampaignsDataView } from "../views/CampaignsDataView";
import { MostrarInstancia } from '../ui/Layout/settings/content/instance/MostrarInstancia';
import { TablaClientesView } from '../views/TablaClientesView';
import { accessDataFormat } from '../../helpers/access';
import { ChatConfig } from '../ui/Layout/settings/content/chat/ChatConfig';
import { EnvioBuilderView } from '../views/EnvioBuilderView';
import { Bots } from '../ui/Layout/settings/content/bots/Bots';
import { CampaignsDataView } from '../views/CampaignsDataView';
import { ClientsConfig } from '../ui/Layout/settings/content/clients/ClientsConfig';
import { useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import { useIsDevsInstance } from '../../hooks/useIsDevsInstance';
import { BrocolyAdminRouter } from './BrocolyAdminRouter';
import { BrocolyAdminView } from '../views/BrocolyAdminView';
import { StorageSettings } from '../ui/Layout/settings/content/storage/StorageSettings';
import { Workspaces } from '../ui/Layout/brocoly-admin/content/workspaces/Workspaces';
import { Solicitudes } from '../ui/Layout/brocoly-admin/content/solicitudes/Solicitudes';
import { EnviosLogsView } from '../views/EnviosLogsView';

const AppRouter = () => {
  const { instance } = useContext(GlobalContext);

  const { isDevsInstance } = useIsDevsInstance();

  return (
    <BrowserRouter basename='/'>
      <Routes>
        <Route index element={<EntryView />} />
        <Route path='/main' element={<InitialView />} />
        <Route
          path='/*'
          element={
            <PrivateRoute>
              <DashboardView />
            </PrivateRoute>
          }
        />
        <Route
          path='/envios/new'
          element={
            <PrivateRoute
              accessData={accessDataFormat('broadcast', 'manage', '/mensajes')}
            >
              <EnvioBuilderView />
            </PrivateRoute>
          }
        />
        <Route
          path='/envios/details'
          element={
            <PrivateRoute
              accessData={accessDataFormat('broadcast', 'manage', '/mensajes')}
            >
              <EnvioBuilderView edit={true} />
            </PrivateRoute>
          }
        />
        <Route
          path='/envios/logs'
          element={
            <PrivateRoute
              accessData={accessDataFormat('broadcast', 'manage', '/mensajes')}
            >
              <EnviosLogsView />
            </PrivateRoute>
          }
        />
        <Route
          path='/envios/logs'
          element={
            <PrivateRoute
              accessData={accessDataFormat('broadcast', 'manage', '/mensajes')}
            >
              <EnviosLogsView />
            </PrivateRoute>
          }
        />
        <Route
          path='/mensajes'
          element={
            <PrivateRoute accessData={accessDataFormat('broadcast', 'show')}>
              <CampaignsView initial tab='campañas' />
            </PrivateRoute>
          }
        />
        <Route
          path='/mensajes/envios'
          element={
            <PrivateRoute accessData={accessDataFormat('broadcast', 'show')}>
              <CampaignsView initial tab='envios' />
            </PrivateRoute>
          }
        />
        <Route
          path='/mensajes/campañas'
          element={
            <PrivateRoute accessData={accessDataFormat('broadcast', 'show')}>
              <CampaignsView initial tab='campañas' />
            </PrivateRoute>
          }
        />
        <Route
          path='/campaigns/new'
          element={
            <PrivateRoute
              accessData={accessDataFormat('broadcast', 'manage', '/mensajes')}
            >
              <SendTemplateView />
            </PrivateRoute>
          }
        />
        <Route
          path='/campaigns/details'
          element={
            <PrivateRoute
              accessData={accessDataFormat('broadcast', 'show', '/mensajes')}
            >
              <SendTemplateView edit={true} />
            </PrivateRoute>
          }
        />
        <Route
          path='/campaigns/data'
          element={
            <PrivateRoute
              accessData={accessDataFormat('broadcast', 'show', '/mensajes')}
            >
              <CampaignsDataView />
            </PrivateRoute>
          }
        />
        <Route
          path='/settings/*'
          element={
            <PrivateRoute accessData={accessDataFormat('settings', 'show')}>
              <ConfigView>
                <MostrarInstancia />
              </ConfigView>
            </PrivateRoute>
          }
        />
        <Route
          path='/settings/templates'
          element={
            <PrivateRoute
              accessData={accessDataFormat('templates', 'show', '/settings')}
            >
              <ConfigView>
                <Templates />
              </ConfigView>
            </PrivateRoute>
          }
        />
        <Route
          path='/settings/bots'
          element={
            <PrivateRoute
              accessData={accessDataFormat('bots', 'show', '/settings')}
            >
              <ConfigView>
                <Bots />
              </ConfigView>
            </PrivateRoute>
          }
        />
        <Route
          path='/settings/templates/edit'
          element={
            <PrivateRoute
              accessData={accessDataFormat('templates', 'manage', '/settings')}
            >
              <ConfigView>
                <EditTemplate edit={true} />
              </ConfigView>
            </PrivateRoute>
          }
        />
        <Route
          path='/settings/teams'
          element={
            <PrivateRoute
              accessData={accessDataFormat('teams', 'show', '/settings')}
            >
              <ConfigView>
                <Teams />
              </ConfigView>
            </PrivateRoute>
          }
        />
        <Route
          path='/settings/channels'
          element={
            <PrivateRoute
              accessData={accessDataFormat('channels', 'show', '/settings')}
            >
              <ConfigView>
                <CanalesContent />
              </ConfigView>
            </PrivateRoute>
          }
        />
        <Route
          path='/settings/workspace'
          element={
            <PrivateRoute
              accessData={accessDataFormat('workspaces', 'show', '/settings')}
            >
              <ConfigView>
                <MostrarInstancia />
              </ConfigView>
            </PrivateRoute>
          }
        />
        <Route
          path='/settings/contacts'
          element={
            <PrivateRoute
              accessData={accessDataFormat('contacts', 'show', '/settings')} // Reemplazar "contacts" por uno nuevo
            >
              <ConfigView>
                <ContactsSettings />
              </ConfigView>
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/settings/error-messages"
          element={
            <PrivateRoute
              accessData={accessDataFormat(
                'errorMessages',
                'show',
                '/settings'
              )}
            >
              <ConfigView>
                <ErrorMessages />
              </ConfigView>
            </PrivateRoute>
          }
        /> */}
        <Route
          path='/settings/integraciones'
          element={
            <PrivateRoute
              accessData={accessDataFormat('integrations', 'show', '/settings')}
            >
              <ConfigView>
                <Integrations />
              </ConfigView>
            </PrivateRoute>
          }
        />
        <Route
          path='/settings/tags'
          element={
            <PrivateRoute
              accessData={accessDataFormat('tags', 'show', '/settings')}
            >
              <ConfigView>
                <Tags />
              </ConfigView>
            </PrivateRoute>
          }
        />
        <Route
          path='/settings/users'
          element={
            <PrivateRoute
              accessData={accessDataFormat('users', 'show', '/settings')}
            >
              <ConfigView>
                <Users />
              </ConfigView>
            </PrivateRoute>
          }
        />
        <Route
          path='/settings/templates/create'
          element={
            <PrivateRoute
              accessData={accessDataFormat(
                'templates',
                'manage',
                '/settings/templates'
              )}
            >
              <ConfigView>
                <CreateTemplate />
              </ConfigView>
            </PrivateRoute>
          }
        />
        <Route
          path='/settings/clients'
          element={
            <PrivateRoute
              accessData={accessDataFormat('clients', 'show', '/settings')}
            >
              <ConfigView>
                <ClientsConfig />
              </ConfigView>
            </PrivateRoute>
          }
        />
        <Route
          path='/settings/storage'
          element={
            <PrivateRoute
              accessData={accessDataFormat('storage', 'show', '/settings')}
            >
              <ConfigView>
                <StorageSettings />
              </ConfigView>
            </PrivateRoute>
          }
        />
        <Route
          path='/dashboard'
          element={
            <PrivateRoute>
              <DashboardView />
            </PrivateRoute>
          }
        />
        <Route
          path='/contactos'
          element={
            <PrivateRoute accessData={accessDataFormat('contacts', 'show')}>
              <ContactoView initial tab='activos' />
            </PrivateRoute>
          }
        />
        <Route
          path='/contactos/leads'
          element={
            <PrivateRoute accessData={accessDataFormat('contacts', 'show')}>
              <ContactoView tab='leads' />
            </PrivateRoute>
          }
        />
        <Route
          path='/contactos/activos'
          element={
            <PrivateRoute accessData={accessDataFormat('contacts', 'show')}>
              <ContactoView tab='activos' />
            </PrivateRoute>
          }
        />
        <Route
          path='/flows'
          element={
            <PrivateRoute accessData={accessDataFormat('bots', 'show')}>
              <FlowsView />
            </PrivateRoute>
          }
        />
        <Route
          path='/flows/new/trigger'
          element={
            <PrivateRoute
              accessData={accessDataFormat('bots', 'manage', '/flows')}
            >
              <EditFlowView type='trigger' />
            </PrivateRoute>
          }
        />
        <Route
          path='/flows/new/broadcast'
          element={
            <PrivateRoute
              accessData={accessDataFormat('bots', 'manage', '/flows')}
            >
              <EditFlowView type='broadcast' />
            </PrivateRoute>
          }
        />
        <Route
          path='/flows/edit'
          element={
            <PrivateRoute
              accessData={accessDataFormat('bots', 'manage', '/flows')}
            >
              <EditFlowView edit={true} />
            </PrivateRoute>
          }
        />
        <Route
          path='/clients'
          element={
            <PrivateRoute accessData={accessDataFormat('clients', 'show')}>
              <TablaClientesView />
            </PrivateRoute>
          }
        />
        <Route
          path='/settings/clients'
          element={
            <PrivateRoute
              accessData={accessDataFormat('clients', 'show', '/settings')}
            >
              <ConfigView>
                <ClientsConfig />
              </ConfigView>
            </PrivateRoute>
          }
        />
        {/* Devs */}
        {isDevsInstance && (
          <>
            <Route
              path='/chat'
              element={
                <PrivateRoute accessData={accessDataFormat('chat', 'show')}>
                  <ChatView />
                </PrivateRoute>
              }
            />
            <Route
              path='/settings/chat'
              element={
                <PrivateRoute
                  accessData={accessDataFormat(
                    'chatSettings',
                    'show',
                    '/settings'
                  )}
                >
                  <ConfigView>
                    <ChatConfig />
                  </ConfigView>
                </PrivateRoute>
              }
            />
            <Route
              path='/presupuestos/*'
              element={
                <PrivateRoute accessData={accessDataFormat('budgets', 'show')}>
                  <PresupuestoView />
                </PrivateRoute>
              }
            />
            <Route
              path='/settings/catalog'
              element={
                <PrivateRoute
                  accessData={accessDataFormat('catalog', 'show', '/settings')}
                >
                  <ConfigView>
                    <Catalog />
                  </ConfigView>
                </PrivateRoute>
              }
            />
          </>
        )}
        //* Brocoly Admin
        <Route
          path='/brocoly-admin/*'
          element={
            <PrivateRoute>
              <BrocolyAdminRouter>
                <BrocolyAdminView>
                  <Workspaces />
                </BrocolyAdminView>
              </BrocolyAdminRouter>
            </PrivateRoute>
          }
        />
        <Route
          path='/brocoly-admin/solicitudes'
          element={
            <PrivateRoute>
              <BrocolyAdminRouter>
                <BrocolyAdminView>
                  <Solicitudes />
                </BrocolyAdminView>
              </BrocolyAdminRouter>
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
