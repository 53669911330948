import { Drawer, Spin, Table } from "antd";
import { getTemplates } from "../../../../../../helpers/getTemplates";
import "./templates.css";

import { useContext, useEffect, useState } from "react";
import { FaRegFilePowerpoint } from "react-icons/fa6";
import { templateColumns } from "./columns";
import { PreviewTemplate } from "./PreviewTemplate";
import { AllButtons } from "./AllButtons";
import { formatoTextHtml } from "../../../../../../helpers/utilidadesTemplates";
import { FiX } from "react-icons/fi";
import { GlobalContext } from "../../../../../context/GlobalContext";
import LoadingIcon from "../../../../Loader/LoadingIcon";
import { handleTemplateVariables } from "../../../../template-form/utils";

export const Templates = () => {
  const { instance, messageApi } = useContext(GlobalContext);
  const [templates, setTemplates] = useState([]);
  const [showTemplate, setShowTemplate] = useState({ open: false, item: null });

  const [templateVariables, setTemplateVariables] = useState(null);
  const [headerMediaType, setHeaderMediaType] = useState("image");
  const [buttonsVars, setButtonsVars] = useState([]);

  const [loadingTemplates, setLoadingTemplates] = useState(false);

  const [showAll, setShowAll] = useState(false);
  // const navigate = useNavigate();

  // const handleEdit = (item) => {
  //   let templateData = Base64.encode(JSON.stringify(item), true);

  //   navigate(`/settings/templates/edit?data=${templateData}`);
  // };

  useEffect(() => {
    const prevData = {
      templateVariables: templateVariables,
      template: showTemplate?.item,
    };

    handleTemplateVariables(
      showTemplate?.item,
      setHeaderMediaType,
      buttonsVars,
      setButtonsVars,
      prevData,
      setTemplateVariables
    );
  }, [showTemplate]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingTemplates(true);
      getTemplates(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200 && Array.isArray(res.data)) {
            setTemplates(res.data);
          } else {
            messageApi.error("Ocurrió un error al obtener plantillas");
          }
        })
        .catch((err) => {
          messageApi.error("Ocurrió un error al obtener plantillas");
        })
        .finally(() => setLoadingTemplates(false));
    }
  }, [instance]);

  return (
    <div className="config-main-wrapper">
      <div className="test-main-header">
        <span
          className="horizontal"
          style={{ justifyContent: "space-between" }}
        >
          <span className="columna" style={{ gap: "0px" }}>
            <span className="config-header-inline">
              <span style={{ height: "24px" }}>
                <FaRegFilePowerpoint size={24} color="var(--primary-color)" />
              </span>
              <span className="header-title-templates">Plantillas</span>
            </span>
            <p className="header-subtitle">Administre sus plantillas</p>
          </span>
          <div className="espacio-botones-contactos">
            {/* <Button
              className="btn-agregar boton-dashed"
              // type="primary"
              onClick={() => navigate("/settings/templates/create")}
            >
              <FiPlus /> Plantilla
            </Button> */}
          </div>
        </span>
      </div>
      {!loadingTemplates ? (
        <>
          <Table
            columns={templateColumns(setShowTemplate)}
            dataSource={templates}
            //loading={loadingTemplates}
            loading={{
              spinning: loadingTemplates,
              indicator: <LoadingIcon size="large" />,
            }}
            rowKey={"id"}
            pagination={false}
            size="small"
            className="tabla-config"
          />
          <Drawer open={showTemplate?.open} placement="right" destroyOnClose>
            <span
              style={{ position: "absolute", top: 12, left: 12 }}
              className="pointer"
            >
              <FiX
                size={20}
                className="pointer"
                onClick={() => {
                  setShowTemplate({ open: false, item: null });
                  setTemplateVariables(null);
                }}
              />
            </span>
            <h4 style={{ marginTop: 16 }}>{showTemplate.item?.name || ""}</h4>
            {templateVariables && (
              <>
                <PreviewTemplate
                  ambito="drawer"
                  // inputText={templateVariables?.body?.value}
                  bodyContent={
                    templateVariables?.body?.value
                      ? formatoTextHtml(templateVariables?.body?.value)
                      : ""
                  }
                  buttonsContent={templateVariables?.buttons?.value || []}
                  footerContent={templateVariables?.footer?.value || ""}
                  headerContent={templateVariables?.header?.value || ""}
                  headerType={templateVariables?.header?.type}
                  showAll={showAll}
                  setShowAll={setShowAll}
                />
                {showAll && (
                  <AllButtons
                    buttonsContent={templateVariables?.buttons?.value || []}
                    setShowAll={setShowAll}
                  />
                )}
              </>
            )}
          </Drawer>
        </>
      ) : (
        <Spin
          style={{
            marginTop: 100,
          }}
          indicator={<LoadingIcon size="large" />}
        />
      )}
    </div>
  );
};
