import { Tooltip } from "antd";
import dayjs from "dayjs";
import { downloadJSON } from "../../../../../utils/downloadJSON";
import { HiDownload } from "react-icons/hi";

export const envioLogsColumns = () => {
  return [
    {
      title: "Fecha de envío",
      key: "timestamp",
      dataIndex: "timestamp",
      render: (data) => {
        if (data) {
          const date = dayjs(data, "x").format("DD/MM/YYYY HH:mm");
          return <p className="monospace-font">{date}</p>;
        }
      },
    },
    {
      title: "...",
      key: "",
      dataIndex: "",
      align: "center",
      width: 60,
      render: (data, item) => {
        //   if (data) {
        //     const date = dayjs(data, "x").format("DD/MM/YYYY HH:mm");
        //     return <p className="monospace-font">{date}</p>;
        //   }

        const date = dayjs(data.timestamp, "x").format("DD/MM/YYYY HH:mm");
        return (
          <span className="horizontal">
            <Tooltip title="Descargar">
              <span>
                <HiDownload
                  size={20}
                  onClick={() => downloadJSON(item, `${date}`)}
                />
              </span>
            </Tooltip>
          </span>
        );
      },
    },
  ];
};
