export const DEFAULT_CONTACT_KEYS = [
  '_id',
  'addresses',
  'birthday',
  'clientId',
  'emails',
  'isArchived',
  'isBlocked',
  'name',
  'org',
  'phones',
  'tags',
  'fullName',
  'phoneNumber',
  'email'
];
