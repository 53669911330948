import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { Base64 } from "js-base64";
import { Button, Popover, Tag, Tooltip, Input, Form } from "antd";
import { IoMegaphoneOutline } from "react-icons/io5";
import { FiEye, FiUsers } from "react-icons/fi";
import { TbLogs, TbTrash } from "react-icons/tb";
import {
  deleteEnvioMasivo,
  duplicateEnvio,
} from "../../../../../../helpers/fetchData/fetchEnviosMasivos";
import { MdWebhook } from "react-icons/md";
import { IoDuplicateOutline } from "react-icons/io5";
import LoadingIcon from "../../../../Loader/LoadingIcon";

const AccionesEnvios = ({
  item,
  setEnviosMasivos,
  setDrawerData,
  setDrawerReturn,
}) => {
  const navigate = useNavigate();

  const { instance, messageApi } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [showPopover, setShowPopover] = useState(false);
  const [loadingBorrar, setLoadingBorrar] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [duplicateData, setDuplicateData] = useState({
    name: "",
  });
  const [dupLoading, setDupLoading] = useState(false);

  const handleNavigate = () => {
    const data = Base64.encode(JSON.stringify(item), true);
    navigate(`/envios/details?payload=${data}`);
  };

  const handleDelete = (id) => {
    setLoadingBorrar(true);
    deleteEnvioMasivo(instance?.userData?.apiKey, id)
      .then((res) => {
        if (res?.status === 200) {
          setEnviosMasivos((prevState) => {
            let array = prevState.filter((element) => element._id !== id);
            return array;
          });
        } else {
          messageApi.error("Ocurrió un error al eliminar una campaña");
        }
      })
      .catch((err) => {
        console.log(err);
        messageApi.error("Ocurrió un error al eliminar una campaña");
      })
      .finally(() => setLoadingBorrar(false));
  };

  const handleDuplicateData = (value, type) => {
    setDuplicateData((prevState) => {
      let data = { ...prevState };

      data[type] = value;

      return data;
    });
  };

  const handleDuplicar = () => {
    if (!duplicateData.name) {
      messageApi.info("Debe ingresar un nombre");
      return;
    }

    const obj = {
      ...item,
      ...duplicateData,
    };

    delete obj._id;

    setDupLoading(true);
    duplicateEnvio(instance?.userData?.apiKey, obj)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Envío creado correctamente");
          const { insertedId } = res.data;

          setEnviosMasivos((prevState) => {
            let array = [{ ...obj, _id: insertedId }, ...prevState];

            return array;
          });

          setShowDuplicate(false);
          setDuplicateData({
            name: "",
          });
        } else {
          messageApi.error("Ocurrió un error al duplicar un envío");
        }
      })
      .catch((error) => {
        console.log("error", error);
        messageApi.error("Ocurrió un error al duplicar un envío");
      })
      .finally(() => setDupLoading(false));
  };

  const goToLogs = () => {
    const data = Base64.encode(
      JSON.stringify({ _id: item?._id, name: item.name }),
      true
    );
    navigate(`/envios/logs?payload=${data}`);
  };

  return (
    <span
      className="horizontal gap8"
      style={{ justifyContent: "center", fontSize: 18 }}
    >
      <Tooltip className="pointer" title="Ver">
        <span>
          <FiEye onClick={handleNavigate} />
        </span>
      </Tooltip>
      <Tooltip className="pointer" title="Suscripciones">
        <span>
          <FiUsers onClick={() => setDrawerData({ open: true, item: item })} />
        </span>
      </Tooltip>
      <Tooltip className="pointer" title="Webhook">
        <span>
          <MdWebhook
            onClick={() => setDrawerReturn({ open: true, item: item })}
          />
        </span>
      </Tooltip>
      <Tooltip title="Duplicar" className="pointer">
        <span>
          <IoDuplicateOutline onClick={() => setShowDuplicate(true)} />
        </span>
      </Tooltip>
      <Tooltip title="Logs" className="pointer">
        <span>
          <TbLogs onClick={goToLogs} />
        </span>
      </Tooltip>
      {showDuplicate && (
        <div className="confirm-wrapper" style={{ position: "fixed" }}>
          <Form
            className="confirm-form"
            style={{ padding: "16px" }}
            onClick={(e) => e.stopPropagation()}
            onFinish={handleDuplicar}
            layout="vertical"
            form={form}
          >
            <b>Duplicar envío</b>
            <Form.Item label="Nombre">
              <Input
                value={duplicateData.name}
                onChange={(v) => handleDuplicateData(v.target.value, "name")}
              />
            </Form.Item>

            <div
              className="botones-wrapper-content"
              style={{ marginTop: "8px", width: "100%" }}
            >
              <Button
                type="primary"
                className="btn-guardar"
                htmlType="submit"
                icon={
                  dupLoading ? (
                    <LoadingIcon size="small" color="#FFFFFF" />
                  ) : null
                }
                style={{ opacity: dupLoading ? 0.65 : 1 }}
              >
                Confirmar
              </Button>
              <Button
                className="btn-cancelar"
                type="secondary"
                onClick={() => setShowDuplicate(false)}
              >
                Cancelar
              </Button>
            </div>
          </Form>
        </div>
      )}
      <Popover
        trigger="click"
        placement="bottomRight"
        open={showPopover}
        content={
          <div className="columna-simple">
            <span
              style={{ marginBottom: 12 }}
            >{`¿Está seguro de borrar ${item?.name}?`}</span>
            <div className="botones-wrapper-content" style={{ width: "100%" }}>
              <Button
                type="primary"
                className="btn-borrar"
                onClick={() => handleDelete(item._id)}
                loading={loadingBorrar}
              >
                Confirmar
              </Button>
              <Button
                className="btn-cancelar"
                type="secondary"
                onClick={() => setShowPopover(false)}
              >
                Cancelar
              </Button>
            </div>
          </div>
        }
      >
        <span>
          <Tooltip className="pointer" title="Eliminar">
            <span>
              <TbTrash onClick={() => setShowPopover(true)} />
            </span>
          </Tooltip>
        </span>
      </Popover>
    </span>
  );
};

export const columnasEnvios = (
  navigate,
  setEnviosMasivos,
  setDrawerData,
  setDrawerReturn
) => {
  const handleNavigate = (item) => {
    const data = Base64.encode(JSON.stringify(item), true);
    navigate(`/envios/details?payload=${data}`);
  };

  return [
    {
      title: "Nombre",
      key: "name",
      dataIndex: "name",
      render: (data, item) => (
        <span
          className="horizontal gap8"
          onClick={() => handleNavigate(item)}
          style={{ cursor: "pointer" }}
        >
          <IoMegaphoneOutline
            style={{ color: "var(--dark-color)" }}
            size={20}
          />
          <b>{data}</b>
        </span>
      ),
    },
    {
      title: "Plantilla",
      key: "template",
      dataIndex: "template",
      render: (data) => {
        return data?.template?.name;
      },
    },
    {
      title: "Creación",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (data) => {
        return dayjs(data, "x").format("DD/MM/YYYY");
      },
    },
    {
      title: "Cron",
      key: "schedule",
      dataIndex: "schedule",
      render: (data) => {
        const wholeData = (
          <span
            className="horizontal gap4"
            style={{ maxWidth: 222, flexWrap: "wrap" }}
          >
            {data?.map((schedule, index) => (
              <Tag color="geekblue" key={index}>
                {schedule}
              </Tag>
            ))}
          </span>
        );

        if (data.length > 2) {
          return (
            <Popover content={wholeData}>
              <span className="horizontal gap4">
                {data.slice(0, 2).map((schedule, index) => (
                  <Tag color="geekblue" key={index}>
                    {schedule.date}
                  </Tag>
                ))}
                <p>...</p>
              </span>
            </Popover>
          );
        }

        return wholeData;
      },
    },
    {
      title: "...",
      key: "",
      dataIndex: "",
      align: "center",
      width: 120,
      render: (data, item) => {
        return (
          <AccionesEnvios
            item={item}
            key={item?._id}
            setEnviosMasivos={setEnviosMasivos}
            setDrawerData={setDrawerData}
            setDrawerReturn={setDrawerReturn}
          />
        );
      },
    },
  ];
};
