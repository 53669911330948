import { useContext, useEffect } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { Form, Select } from 'antd';
import { Tag } from '../../tag/Tag';

const MAX_TAG_COUNT = 5;

export const TagContact = ({ editMode, form }) => {
  const { rightDrawerConfig, etiquetas } = useContext(GlobalContext);

  const { item } = rightDrawerConfig;

  useEffect(() => {
    const contactTags = etiquetas
      ?.filter((etiqueta) => item?.tags?.includes(etiqueta._id))
      .map((etiqueta) => etiqueta._id);

    form.setFieldsValue({ tags: contactTags });
  }, [etiquetas, item, form]);

  const options = etiquetas.map((etiqueta) => ({
    label: (
      <Tag hex={etiqueta.color} nombre={etiqueta.name} key={etiqueta._id} />
    ),
    value: etiqueta._id
  }));

  const filterOptions = (value, option) => {
    return option?.label?.props?.nombre
      ?.toLowerCase()
      ?.includes(value.toLowerCase());
  };

  return (
    <Form
      className='form-nodos'
      form={form}
      name='contactTags'
      layout='vertical'
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Etiquetas</p>

      <Form.Item name='tags'>
        <Select
          mode='multiple'
          allowClear
          maxTagCount={MAX_TAG_COUNT}
          placeholder='Seleccione etiquetas'
          options={options}
          filterOption={filterOptions}
          showSearch
        />
      </Form.Item>
    </Form>
  );
};
