import { Button } from 'antd';
import { FiPaperclip } from 'react-icons/fi';
import { CardPricing } from './CardPricing';
import { MdOutlineCloud } from 'react-icons/md';

export const BuyStorage = ({ setShowBuyStorage }) => {
  return (
    <div className='config-main-wrapper'>
      <div className='test-main-header'>
        <span
          className='horizontal'
          style={{ justifyContent: 'space-between' }}
        >
          <span className='columna' style={{ gap: '0px' }}>
            <span className='config-header-inline'>
              <span style={{ height: '24px' }}>
                <FiPaperclip size={24} color='var(--primary-color)' />
              </span>
              <span className='header-title-templates'>
                Comprar almacenamiento
              </span>
            </span>
            <p className='header-subtitle'>
              Seleccione un plan y luego haga clic en <i>Elegir plan</i>
            </p>
          </span>

          <div className='espacio-botones-contactos'>
            <Button
              className='btn-agregar boton-dashed'
              onClick={() => setShowBuyStorage(false)}
            >
              Volver
            </Button>
          </div>
        </span>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div className='CardBot storage-card' style={{ gap: 16 }}>
          <span>
            <MdOutlineCloud size={48} />
          </span>

          <div className='storage-card-content'>
            <span>6 MB (60%)</span>
            <span>utilizados</span>
          </div>
        </div>

        <Button type='primary'>Elegir plan</Button>
      </div>

      <div className='storage-pricing-container'>
        <CardPricing title='BASIC' size='100 MB' current />
        <CardPricing title='STANDARD' size='500 MB' />
        <CardPricing title='PREMIUM' size='1 GB' />
      </div>
    </div>
  );
};
