import { Image } from "antd";
import dayjs from "dayjs";

export const workspacesColumns = () => {
  return [
    { title: "Nombre", key: "orgName", dataIndex: "orgName" },
    { title: "Base de datos", key: "name", dataIndex: "name" },
    {
      title: "Imagen",
      key: "profilePicture_url",
      dataIndex: "profilePicture_url",
      render: (data) => {
        const style = { borderRadius: 8 };
        const size = 70;
        
        if (data) {
          return <Image src={data} width={size} preview={true} style={style} />;
        } else {
          return (
            <Image
              src="/assets/image-placeholder.png"
              width={size}
              style={style}
            />
          );
        }
      },
    },
    {
      title: "Fecha de creación",
      key: "createdAt",
      dataIndex: "createdAt",
      align: "right",
      width: 140,
      render: (data) => {
        if (data) {
          return dayjs(data, "x")?.format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
    },
  ];
};
