import { useContext, useState, useEffect, useMemo } from "react";
import { Button, Transfer, Form } from "antd";
import { GlobalContext } from "../../../context/GlobalContext";
import { actualizarLista } from "./helpers/actualizarLista";
import { getContactName } from "../campaigns/sendTemplates/utils/getContactName"; 
import { putCliente } from "./fetchData/formCliente/putCliente";
import LoadingIcon from '../../Loader/LoadingIcon';
import "./formClientes.css";

const filterOption = (value, option) => {
    return option?.fullName?.toLowerCase()?.includes(value.toLowerCase());
};

function LinkContactsToClient({ editarClientesValues, setDrawerLinkContacts, setCliente, setRefetchClientes }) {

    const [formCliente] = Form.useForm();

    const {
        messageApi,
        contactos,
        instance
    } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);
    const [targetKeys, setTargetKeys] = useState([]);

    const onChangeTransfer = (newTargetKeys, direction, moveKeys) => {
        setTargetKeys(newTargetKeys);
    };

    const renderItem = (item) => {
        return {
          label: item.fullName,
          value: item._id,
        };
    };

    useEffect(() => {

        if (editarClientesValues) {
            //console.log('editarClientesValues', editarClientesValues)
            setTargetKeys(editarClientesValues.contacts)
        };

    }, []);


    const onFinish = (v) => {
        //console.log('relacionar contactos a cliente', v);

        if (instance?._id) {

            let { key, state, ...client } = editarClientesValues;

            let data = {
                ...client,
                contacts: targetKeys
            };

            setLoading(true);
            putCliente(data, instance)
                .then((res) => {
                    console.log(res);
                    if (res?.status === 200) {
                        messageApi.success("Contactos relacionados correctamente");
                        setDrawerLinkContacts(false);
                        setCliente(undefined);
                        // actualizarLista(
                        //     "editar",
                        //     data,
                        //     setClientes,
                        //     editarClientesValues._id,
                        //     idTablaSelecc
                        // );
                        setRefetchClientes(prevState => {
                            //console.log('prevState setRefechClientes', prevState)
                            return !prevState
                        })

                    } else {
                        messageApi.error("Ocurrió un error al relacionar contactos");
                    }
                })
                .catch((error) => {
                    // console.log('error catch', error);
                    messageApi.error("Ocurrió un error al relacionar contactos");
                })
                .finally(() => setLoading(false));
        }

    };

    const formattedContacts = useMemo(() => {
        if (!contactos || contactos.length < 1) {
          return [];
        }
    
        let formatted = contactos.map((element) => {
          element.fullName = getContactName(element.name);
    
          return element;
        });
    
        return formatted;
      }, [contactos]);

    const handleSearch = (dir, value) => {
        //console.log('search:', dir, value);
    };

    //console.log('editarClientesValues', editarClientesValues)
    //console.log('targetKeys', targetKeys)

    return (
        <Form
            form={formCliente}
            onFinish={onFinish}
            layout="vertical"
            style={{ padding: "24px" }}
            className="form-clientes"
        >
            <p style={{ marginBottom: 16, fontWeight: 600 }}>Relacionar contactos</p>

            <Transfer
                style={{ width: "100%" }}
                dataSource={formattedContacts || []}
                targetKeys={targetKeys}
                filterOption={filterOption}
                onChange={onChangeTransfer}
                render={renderItem}
                titles={["Contactos", "Seleccionados"]}
                showSelectAll
                listStyle={{
                    width: "calc(50% - 20px)",
                    height: "calc(100vh - 220px)",
                    minHeight: "400px",
                }}
                rowKey={(record) => record._id}
                showSearch
                onSearch={handleSearch}
                locale={{
                    itemUnit: "",
                    itemsUnit: "",
                    searchPlaceholder: "Buscar",
                }}
            />

            <Form.Item className="form-clientes-custom-footer">
                <div className="botones-wrapper-content">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-guardar"
                        size="large"
                        icon={loading ? <LoadingIcon size="default" color="#FFFFFF" /> : null}
                    >
                        Guardar
                    </Button>
                    <Button
                        className="btn-cancelar"
                        size="large"
                        type="secondary"
                        onClick={() => {
                            setCliente(undefined);
                            setDrawerLinkContacts(false);
                        }}
                    >
                        Cancelar
                    </Button>
                </div>
            </Form.Item>
        </Form>
    )
}

export default LinkContactsToClient