export const getColorQualityRating = (whatsappProfile) => {
  if (whatsappProfile && whatsappProfile[0]) {
    const { quality_rating } = whatsappProfile[0];

    switch (quality_rating) {
      case 'GREEN':
        return 'var(--primary-color)';
      case 'YELLOW':
        return 'orange';
      case 'RED':
        return 'red';
      default:
        return 'black';
    }
  }
};

export const getTitleQualityRating = (whatsappProfile) => {
  if (whatsappProfile && whatsappProfile[0]) {
    const { quality_rating } = whatsappProfile[0];

    switch (quality_rating) {
      case 'GREEN':
        return 'Alta';
      case 'YELLOW':
        return 'Media';
      case 'RED':
        return 'Mala';
      default:
        return 'Desconocido';
    }
  }
};
