import { Button, Form, Input } from "antd";
import { FlowContext } from "../../../../context/FlowContext";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useContext, useEffect, useState } from "react";
import { updateNode } from "../utils/updateNode";
import { HeaderForm } from "./componentsForm/HeaderForm";
import { FiUsers } from "react-icons/fi";
import { BoxInfo } from "../../../box-info/BoxInfo";
import { handleKeyPress } from "../utils/httpRequest";
import { handleFlowVariables } from "../utils/handleFlowVariables";

const PREFIX = "client_";

export const ClientsByContactForm = () => {
  const {
    flowVariables,
    setFlowVariables,
    nodoSeleccionado,
    setMostrarDrawer,
    setNodes,
    setEdges,
  } = useContext(FlowContext);

  const { messageApi } = useContext(GlobalContext);

  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState("");

  const onFinish = (values) => {
    values.variable = {};

    if (!values?.variableName) {
      messageApi.info("Ingrese un nombre para la variable");
    }

    values.variable.name =
      PREFIX +
      values?.variableName.substring(values?.variableName.indexOf("_") + 1);
    values.variable.type = "string";

    let flag = handleFlowVariables(
      setFlowVariables,
      nodoSeleccionado.id,
      values.variable,
      messageApi,
      setNodes,
      setEdges
    );

    if (!flag) return;

    // console.log("nodoSeleccionado form", nodoSeleccionado);

    // console.log("values", values);

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      setNombreNodo(data.label);

      let variableName = "";

      if (data.variableName) {
        let variable = data.variableName;
        variableName = variable.substring(variable.indexOf("_") + 1);
      }

      form.setFieldsValue({
        variableName: variableName,
      });

      //   if (data.headerText !== undefined) {
      //     setHeaderText(data.headerText);
      //   }

      //   if (data.bodyText !== undefined) {
      //     setBodyText(data.bodyText);
      //   }

      //   if (data.footerText !== undefined) {
      //     setFooterText(data.footerText);
      //   }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="formBotones"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<FiUsers style={{ color: "var(--dark-color)" }} size={32} />}
      />

      <BoxInfo message="Enviaremos una lista de clientes asociados al contacto que se comunica al bot" />

      <div className="form-item-column" style={{ marginTop: 16 }}>
        <span className="horizontal" style={{ width: "100%" }}>
          <Form.Item
            style={{ width: "100%" }}
            label="Nombre de la variable"
            name="variableName"
            onKeyPress={(e) => handleKeyPress(e)}
            rules={[
              { required: true, message: "Campo requerido" },
              {
                pattern: /^[a-zA-Z0-9-]+$/,
                message: 'Se permiten solo letras, números o "-"',
              },
            ]}
          >
            <Input addonBefore={PREFIX} style={{ color: "#252525" }} />
          </Form.Item>
        </span>
        <BoxInfo
          message='Esta variable tomará el indice que se seleccione en la lista para
              ser utilizado posteriormente. Este indice remplazará "[idx]"'
        />
      </div>

      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
