import { AiOutlineApi } from "react-icons/ai";
import { CiSquareQuestion } from "react-icons/ci";

const getItem = (label, key, icon, children, type) => {
  return {
    label,
    key,
    icon,
    children,
    type,
  };
};

export const adminMenuItems = () => {
  return [
    getItem("Workspaces", "workspaces", <AiOutlineApi />),
    getItem("Solicitudes", "solicitudes", <CiSquareQuestion />),
  ];
};
