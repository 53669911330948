import axios from "axios";

export const fetchInstance = async (
  user,
  messageApi,
  setLoadingInstance,
  setWorkspaces,
  getAccessTokenSilently
) => {
  try {
    if (user) {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "https://api.brocoly.ar/api",
          issuerBaseURL: "https://binamics.us.auth0.com/",
          tokenSigningAlg: "RS256",
        },
      });

      setLoadingInstance(true);

      getInstance(token)
        .then((res) => {
          if (res?.status === 200) {
            const { data, user } = res.data;

            let array = data?.instance
              ?.map((workspace) => {
                let association = user?.associations?.find(
                  (element) => element.instanceId === workspace._id
                );

                return {
                  ...workspace,
                  userData: { userId: user._id, ...association },
                };
              })
              ?.filter((workspace) => {
                if (workspace.userData?.status === false) {
                  return false;
                }

                return true;
              });

            setWorkspaces({ ...data, instance: array });
          } else {
            messageApi.error("Error al obtener workspace");
          }
        })
        .catch((error) => {
          messageApi.error("Error al obtener workspace");
        })
        .finally(() => {
          setLoadingInstance(false);
        });
    }
  } catch (error) {
    console.log("error", error);
    messageApi.error("Error al obtener workspace");
    setLoadingInstance(false);
  }
};

export const getInstance = async (token) => {
  try {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/getInstance`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const uploadProfilePicture = async (key, data) => {
  try {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PRODUCCION}/uploadProfilePicture`,
      headers: {
        "Content-Type": "application/json",
        Authorization: key,
      },
      data: data,
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserStatus = async (data, apiKey) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/chat/agent/agentStatus`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const lastLoginUpdate = async (apiKey) => {
  try {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/users/login`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserAccess = async (apiKey, data) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/userAccess`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const validateInstanceName = async (nombre) => {
  try {
    // let data = JSON.stringify({
    //   name: nombre,
    // });

    // nombre =

    const data = { name: nombre };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/validateName`,
      headers: {
        Authorization: "asdasdas",
        "Content-Type": "application/json",
      },
      data: data,
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const confirmDraft = async (data) => {
  try {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/confirmDraft`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const createDraftWorkspace = async (data) => {
  try {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/draftWorkspace`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
