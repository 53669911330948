import { Tag } from "antd";

export const solicitudesColumns = () => {
  return [
    {
      title: "Nombre de la organización",
      key: "orgName",
      dataIndex: "orgName",
    },
    {
      title: "Nombre del contacto",
      key: "contactName",
      dataIndex: "contactName",
    },
    {
      title: "Teléfono",
      key: "phoneNumber",
      dataIndex: "phoneNumber",
      width: 120,
      align: "right",
    },
    { title: "Email", key: "userEmail", dataIndex: "userEmail" },
    {
      title: "Estado",
      key: "status",
      dataIndex: "status",
      render: (data) => {
        if (data === "MAIL PENDING") {
          return <Tag color="orange">{data}</Tag>;
        } else if (data === "ALTA PENDING") {
          return <Tag color="geekblue">{data}</Tag>;
        }
      },
    },
    { title: "OTP", key: "otp", dataIndex: "otp", width: 80, align: "right" },
  ];
};
