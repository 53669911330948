import LoadingIcon from '../../../../../../Loader/LoadingIcon';
import { MessageQuality } from './MessageQuality';
import { MetaVerification } from './MetaVerification';
import { MessageLimit } from './MessageLimit';
import { useContext } from 'react';
import { GlobalContext } from '../../../../../../../context/GlobalContext';

export const MetaBusinessInfo = ({
  businessCompliance,
  loadingBusinessCompliance
}) => {
  const { loadingProfile } = useContext(GlobalContext);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: 350,
        paddingLeft: 8,
        borderLeft: 'dashed 1px #8a8c8f99'
      }}
    >
      <span
        className='horizontal'
        style={{
          justifyContent: 'space-between'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 6,
            fontSize: 15,
            fontWeight: 500,
            color: '#252525'
          }}
        >
          Meta Business
        </div>
      </span>

      {loadingBusinessCompliance || loadingProfile ? (
        <span
          style={{
            height: '100%',
            width: '100%',
            display: 'grid',
            placeItems: 'center'
          }}
        >
          <LoadingIcon size='large' />
        </span>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            marginTop: 8
          }}
        >
          <MessageQuality />
          <MetaVerification businessCompliance={businessCompliance} />
          <MessageLimit businessCompliance={businessCompliance} />
        </div>
      )}
    </div>
  );
};
