import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AppRouter from './components/router/AppRouter';
import { ConfigProvider, message, notification } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import { GlobalContext } from './components/context/GlobalContext';
import './App.css';
import {
  fetchInstance,
  updateUserStatus
} from './helpers/fetchData/fetchInstance';
import { fetchApplication } from './helpers/fetchData/fetchApplication';
import { fetchTags } from './helpers/fetchData/fetchTags';
import { fetchProfile } from './helpers/fetchData/fetchProfile';
import { fetchContacts } from './helpers/fetchData/fetchContacts';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import { io } from 'socket.io-client';
import { getInvitationsByInstance } from './helpers/fetchData/fetchInvitation';
import { openNotification } from './helpers/openNotification';

dayjs.extend(relativeTime);
dayjs.locale('es');

const socket = io(process.env.REACT_APP_SOCKET_URL, {
  autoConnect: false,
  timeout: 20000,
  transports: ['polling', 'websocket']
});

const App = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const { isAuthenticated, isLoading, user, getAccessTokenSilently } =
    useAuth0();
  const [sideDrawerConfig, setSideDrawerConfig] = useState({
    visible: false,
    content: ''
  });
  const [activeMenuItem, setActiveMenuItem] = useState(false);
  const [contactos, setContactos] = useState([]);
  const [loadingContactos, setLoadingContactos] = useState(false);
  const [rightDrawerConfig, setRightDrawerConfig] = useState({
    visible: false,
    content: ''
  });

  const [whatsappProfile, setWhatsappProfile] = useState([]);
  const [instance, setInstance] = useState({});
  const [workspaces, setWorkspaces] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);
  const [appStage, setAppStage] = useState(4);
  const [application, setApplication] = useState([]);
  const [templateStage, setTemplateStage] = useState(0);
  const [refreshContactos, setRefreshContactos] = useState(false);
  const [loadingApplication, setLoadingApplication] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  // Los valores de currentPlan son temporales:
  const [currentPlan, setCurrentPlan] = useState({
    name: 'Free',
    size: 100000000
  });
  const [currentStorageSize, setCurrentStorageSize] = useState(0);

  const [isUserActive, setIsUserActive] = useState(false);

  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingInstance, setLoadingInstance] = useState(true);
  const [notificationApi, contextHolderNotif] = notification.useNotification();

  // Tabla clientes
  const [clientes, setClientes] = useState([]);
  const [filtros, setFiltros] = useState([]);

  const [loadingEtiquetas, setLoadingEtiquetas] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      fetchInstance(
        user,
        messageApi,
        setLoadingInstance,
        setWorkspaces,
        getAccessTokenSilently
      );
    }
  }, [user, isLoading]);

  useEffect(() => {
    setApplication([]);
    fetchApplication(
      instance,
      setApplication,
      messageApi,
      setLoadingApplication
    );

    fetchTags(instance, setEtiquetas, messageApi, setLoadingEtiquetas);
  }, [instance]);

  useEffect(() => {
    if (application?.length > 0 && instance?.userData?.apiKey) {
      fetchProfile(
        application,
        setWhatsappProfile,
        setLoadingProfile,
        messageApi,
        instance.userData.apiKey
      );
    } else {
      setWhatsappProfile([]);
    }
  }, [application]);

  useEffect(() => {
    fetchContacts(instance, setContactos, messageApi, setLoadingContactos);
  }, [instance, refreshContactos]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getInvitationsByInstance(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            const { activeUsers } = res.data;

            setUsuarios(activeUsers || []);
          } else {
            messageApi.error('Ocurrió un error al obtener usuarios');
          }
        })
        .catch((error) =>
          messageApi.error('Ocurrió un error al obtener usuarios')
        );
    }
  }, [instance]);

  useEffect(() => {
    // Evita que se muestre error "benigno" interno de una de las librerias usadas en campañas
    const handleError = (e) => {
      if (e.message.startsWith('ResizeObserver loop')) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  useEffect(() => {
    if (instance?.userData?.apiKey && application?.[0]) {
      socket.auth = {
        token: instance.userData.apiKey,
        instanceName: instance.name,
        metaToken: application[0].token,
        phoneNumberId: application[0].lines?.[0]?.phoneNumberId
      };
      socket.connect();

      return () => {
        socket.disconnect();
      };
    }
  }, [instance, application]);

  useEffect(() => {
    // Escucha cada notificación que llega.
    const readNotification = (notification) => {
      if (notification?.notification?.content === 'newChat') {
        openNotification(
          notificationApi,
          'info',
          'Se creó un nuevo ticket',
          null
        );
      }
    };

    socket.on('notification', readNotification);

    return () => {
      socket.off('notification', readNotification);
    };
  }, []);

  useEffect(() => {
    //EVITA MODIFICAR VALOR INPUT NUMBER CUANDO SCROLLA
    const handleWheelNumber = (event) => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };

    document.addEventListener('wheel', handleWheelNumber);

    return () => {
      document.removeEventListener('wheel', handleWheelNumber);
    };
  }, []);

  const handleBeforeUnload = async (event) => {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (instance?.userData) {
      const data = JSON.stringify({
        agent: instance.userData.userId,
        status: false,
        apiKey: instance.userData.apiKey
      });

      navigator.sendBeacon(
        `${process.env.REACT_APP_API_PRODUCCION}/chat/agent/agentInactiveBeacon`,
        data
      );
    }
  };

  useEffect(() => {
    window.addEventListener('unload', handleBeforeUnload);

    return () => {
      window.removeEventListener('unload', handleBeforeUnload);
    };
  }, [instance]);

  const handleOnload = () => {
    const data = {
      agent: instance.userData.userId,
      status: true
    };

    updateUserStatus(data, instance.userData.apiKey)
      .then((res) => {
        if (res?.status === 200) {
          setIsUserActive(true);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (instance?.userData?.userId) {
      handleOnload();
    }
  }, [instance]);

  return (
    <GlobalContext.Provider
      value={{
        sideDrawerConfig,
        setSideDrawerConfig,
        activeMenuItem,
        setActiveMenuItem,
        whatsappProfile,
        setWhatsappProfile,
        instance,
        setInstance,
        contactos,
        setContactos,
        rightDrawerConfig,
        setRightDrawerConfig,
        etiquetas,
        setEtiquetas,
        appStage,
        setAppStage,
        application,
        setApplication,
        templateStage,
        setTemplateStage,
        refreshContactos,
        setRefreshContactos,
        messageApi,
        loadingInstance,
        setLoadingInstance,
        workspaces,
        setWorkspaces,
        loadingApplication,
        clientes,
        setClientes,
        usuarios,
        setUsuarios,
        isUserActive,
        setIsUserActive,
        filtros,
        setFiltros,
        notificationApi,
        setLoadingContactos,
        loadingContactos,
        loadingEtiquetas,
        setLoadingEtiquetas,
        loadingProfile,
        setLoadingProfile,
        currentPlan,
        setCurrentPlan,
        currentStorageSize,
        setCurrentStorageSize
      }}
    >
      <ConfigProvider
        locale={esES}
        theme={{
          token: {
            colorPrimary: '#77BE42'
          },
          components: {
            Select: {
              colorPrimary: '#77BE42'
            }
          }
        }}
      >
        {contextHolder}
        {contextHolderNotif}
        <AppRouter isAuthenticated={isAuthenticated} isLoading={isLoading} />
      </ConfigProvider>
    </GlobalContext.Provider>
  );
};

export default App;
