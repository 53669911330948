export const getTitleStatus = (businessCompliance) => {
  if (businessCompliance && businessCompliance?.status) {
    switch (businessCompliance?.status) {
      case 'CONNECTED':
        return 'Conectado';
      case 'FLAGGED':
        return 'Marcado';
      case 'RESTRICTED':
        return 'Restringido';
      case 'UNKNOWN':
        return 'Desconocido';
      default:
        return 'Error';
    }
  }
};

export const getColorStatus = (businessCompliance) => {
  if (businessCompliance && businessCompliance?.status) {
    switch (businessCompliance?.status) {
      case 'CONNECTED':
        return 'green';
      case 'FLAGGED':
        return 'orange';
      case 'RESTRICTED':
        return 'red';
      case 'UNKNOWN':
        return '#6b6b6b';
      default:
        return '#6b6b6b';
    }
  }
};
