import { useContext } from 'react';
import { GlobalContext } from '../../../../context/GlobalContext';
import './styles.css';
import { NewTag } from './plusContent/NewTag';
import { ContactEditor } from '../../../drawers/ContactEditor';
import { CargaMedia } from '../../../modals/CargaMedia';

const PlusContent = () => {
  const { sideDrawerConfig } = useContext(GlobalContext);

  const handleContent = () => {
    switch (sideDrawerConfig.tab) {
      case 'contact':
        return <ContactEditor />;
      case 'tag':
        return <NewTag />;
      case 'media':
        return <CargaMedia modal={false} ambito='plusContent' />;
      default:
        break;
    }
  };

  return handleContent();
};

export default PlusContent;
