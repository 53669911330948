import axios from "axios";

export const getAllInstances = async (apiKey) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PRODUCCION}/brocolyAdmin/instances`,
      headers: {
        Authorization: apiKey,
      },
    };

    const res = await axios.request(config);

    return res;
  } catch (error) {
    console.log("error", error);
  }
};

export const getDraftWorkspaces = async (apiKey) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PRODUCCION}/brocolyAdmin/draftWorkspaces`,
      headers: {
        Authorization: apiKey,
      },
    };

    const res = await axios.request(config);

    return res;
  } catch (error) {
    console.log("error", error);
  }
};
