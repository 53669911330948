import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Image, Tooltip, Row } from "antd";
import { useContext, useEffect, useMemo } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { FiLogOut } from "react-icons/fi";
import { PlusOutlined } from "@ant-design/icons";
import { InvitationCard } from "./InvitationCard";
import { TbSquareKey } from "react-icons/tb";
import { MdOutlinePendingActions } from "react-icons/md";

export const PickInstance = () => {
  const { setInstance, workspaces } = useContext(GlobalContext);

  const { logout, user } = useAuth0();

  const draft = useMemo(() => {
    if (workspaces?.draft?.idDraft) {
      return workspaces.draft;
    }

    return null;
  }, [workspaces]);

  return (
    <div className="workspaces-view-wrapper">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15vh",
        }}
      >
        {user.picture && (
          <Image
            preview={false}
            style={{
              width: "90px",
              borderRadius: "50px",
              marginBottom: "30px",
              padding: "1px",
              border: "1px solid #f0f0f0",
            }}
            src={user.picture}
            referrerPolicy={"no-referrer"}
          />
        )}

        <p
          style={{
            fontSize: "24px",
            color: "#1e2c39",
            marginBottom: "10px",
          }}
        >
          Bienvenido {user?.name}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {workspaces.invitations?.length > 0 && (
          <div className="invitations-wrapper">
            {workspaces?.invitations?.map((invitation) => {
              return (
                <InvitationCard invitation={invitation} key={invitation._id} />
              );
            })}
          </div>
        )}
        <Card
          className="card-contenedor-workspace"
          style={{
            width: "472px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
          }}
        >
          <Row
            style={{
              width: 424,
              display: "flex",
              justifyContent: "center",
            }}
            wrap={true}
          >
            {workspaces?.instance?.map((workspace) => {
              return (
                <div key={workspace._id}>
                  <Tooltip title={workspace.orgName} placement="bottom">
                    <Card
                      className="workspace-card"
                      hoverable
                      styles={{ body: { padding: 0 } }}
                      onClick={() => setInstance(workspace)}
                    >
                      <Image
                        preview={false}
                        style={{ width: "100%" }}
                        alt="Workspace"
                        src={
                          workspace.profilePicture_url ||
                          "/assets/image-placeholder.png"
                        }
                      />
                    </Card>
                  </Tooltip>
                </div>
              );
            })}
            {draft?.idDraft ? (
              <>
                {draft.status === "MAIL PENDING" ? (
                  <Tooltip
                    placement="bottom"
                    key="draft1"
                    title="Ingresar código de confirmación"
                  >
                    <Card
                      onClick={() => setInstance(workspaces.draft)}
                      className="card-workspace"
                      hoverable
                      styles={{ body: { padding: 0 } }}
                    >
                      {/* <p style={{ fontSize: 13 }}>Confirmar código</p> */}
                      <TbSquareKey size={32} />
                    </Card>
                  </Tooltip>
                ) : (
                  <Tooltip
                    placement="bottom"
                    key="draft1"
                    title="Nos contactaremos a la brevedad"
                  >
                    <Card
                      // onClick={() => setInstance(workspaces.draft)}
                      className="card-workspace"
                      hoverable
                      styles={{ body: { padding: 0 } }}
                    >
                      <MdOutlinePendingActions size={32} />
                    </Card>
                  </Tooltip>
                )}
              </>
            ) : (
              <Tooltip
                title="Agregar Workspace"
                placement="bottom"
                key="agregar01"
              >
                <Card
                  className="card-workspace"
                  hoverable
                  onClick={() => setInstance({ create: true })}
                  styles={{ body: { padding: 0 } }}
                >
                  <PlusOutlined
                    style={{ fontSize: "32px", color: "#1e2c39" }}
                  />
                </Card>
              </Tooltip>
            )}
          </Row>
        </Card>
        <div
          className="workspace-logout"
          style={{
            position: "absolute",
            bottom: "100px",
            left: "50%",
            transform: "translate(-50%, 0%)",
            color: "#1e2c39",
          }}
          onClick={() => {
            logout({ returnTo: window.location.origin });
          }}
        >
          <Button
            size="large"
            type="secondary"
            style={{
              color: "#1e2c39",
              border: "none",
              boxShadow: "none",
              height: "auto",
            }}
          >
            <FiLogOut style={{ fontSize: "20px" }} />
            <p>Salir</p>
          </Button>
        </div>
      </div>
    </div>
  );
};
