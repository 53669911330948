import { ButtonsForm } from "./ButtonsForm";
import { ListForm } from "./ListForm";
import { TriggerForm } from "./TriggerForm";
import { BranchForm } from "./BranchForm";
import { TimeForm } from "./TimeForm";
import { MessageForm } from "./MessageForm";
import { TranscribeAudioForm } from "./TranscribeAudioForm";
import { HttpRequestForm } from "./HttpRequestForm";
import { SetDelayForm } from "./SetDelayForm";
import { SendTemplateForm } from "./SendTemplateForm";
import { IterableListForm } from "./IterableListForm";
import { SaveResponseForm } from "./SaveResponseForm";
import { SetVariablesForm } from "./SetVariablesForm";
// import { TextToSpeechForm } from "./TextToSpeechForm";
import { InitialForm } from "./InitialForm";
import { OneTimePasswordForm } from "./OneTimePasswordForm";
import { MultiProductForm } from "./MultiProductForm";
import { SingleProductForm } from "./SingleProductForm";
import { BudgetForm } from "./BudgetForm";
import { EncodeForm } from "./EncodeForm";
import { CtaButtonForm } from "./CtaButtonForm";
import { DecodeForm } from "./DecodeForm";
import { CallFunctionForm } from "./CallFunctionForm";
import { ContactAgentForm } from "./ContactAgentForm";
import { FormulaForm } from "./FormulaForm";
import { SendMessageToChatForm } from "./SendMessageToChatForm";
import { TagContactForm } from "./TagContactForm";
import { SubscriptionForm } from "./SubscriptionForm";
import { GoToFlowForm } from "./GoToFlowForm";
import { SendEmailForm } from "./SendEmailForm";
import { NoteForm } from "./NoteForm";
import { TagBranchForm } from "./TagBranchForm";
import { ClientsByContactForm } from "./ClientsByContactForm";

export const HandleForm = ({
  nodoSeleccionado,
  setNodoSeleccionado,
  mostrarDrawer,
  setMostrarDrawer,
}) => {
  const handleForm = () => {
    switch (nodoSeleccionado.type) {
      case "message":
        return <MessageForm />;
      case "trigger":
        return <TriggerForm />;
      case "inicial":
        return <InitialForm />;
      case "budget":
        return <BudgetForm />;
      case "buttons":
        return <ButtonsForm />;
      case "list":
        return <ListForm />;
      case "branch":
        return <BranchForm />;
      case "tagBranch":
        return <TagBranchForm />;
      case "callFunction":
        return <CallFunctionForm />;
      case "dateTime":
        return <TimeForm />;
      case "transcribeAudio":
        return <TranscribeAudioForm />;
      case "httpRequest":
        return <HttpRequestForm />;
      case "iterableList":
        return <IterableListForm />;
      case "oneTimePassword":
        return <OneTimePasswordForm />;
      case "setDelay":
        return <SetDelayForm />;
      case "sendTemplate":
        return <SendTemplateForm />;
      case "saveResponse":
        return <SaveResponseForm />;
      case "setVariables":
        return <SetVariablesForm />;
      // case "textToSpeech":
      // return <TextToSpeechForm />;
      case "sendMultiProduct":
        return <MultiProductForm />;
      case "sendSingleProduct":
        return <SingleProductForm />;
      case "encode":
        return <EncodeForm />;
      case "cta_button":
        return <CtaButtonForm />;
      case "decode":
        return <DecodeForm />;
      case "contactAgent":
        return <ContactAgentForm />;
      case "formula":
        return <FormulaForm />;
      case "sendMessageToChat":
        return <SendMessageToChatForm />;
      case "tagContact":
        return <TagContactForm />;
      case "subscription":
        return <SubscriptionForm />;
      case "goToFlow":
        return <GoToFlowForm />;
      case "sendEmail":
        return <SendEmailForm />;
      case "note":
        return <NoteForm />;
      case "clientsByContact":
        return <ClientsByContactForm />;
      default:
        return <span>...Tipo no soportado</span>;
    }
  };

  return (
    <div className="common-node-forms">
      {nodoSeleccionado?.type && handleForm()}
    </div>
  );
};
