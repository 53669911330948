import { Table } from "antd";
import { contactsLeadsColumns } from "./columns";
import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import LoadingIcon from "../../../Loader/LoadingIcon";
import { getContactsLeads } from "../../../../../helpers/fetchData/fetchContactsLeads";

export const TablaContactsLeads = () => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoading(true);
      getContactsLeads(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setLeads(res.data);
          } else {
            messageApi.error("Ocurrió un error al obtener leads");
          }
        })
        .catch((error) => {
          console.log("error", error);
          messageApi.error("Ocurrió un error al obtener leads");
        })
        .finally(() => setLoading(false));
    }
  }, [instance]);

  const filteredData = useMemo(() => {
    return [];
  }, [leads]);

  return (
    <Table
      columns={contactsLeadsColumns()}
      dataSource={leads || []}
      rowKey="_id"
      size="small"
      pagination={{
        pageSize: 10,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} de ${total} leads`,
      }}
      // className="tabla-contactos"
      loading={{
        spinning: loading,
        indicator: <LoadingIcon size="large" />,
      }}
    />
  );
};
