import { Button, Tooltip, Table, Tabs, Drawer } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { FiEdit3, FiPlus } from "react-icons/fi";
import { AiOutlineSetting } from "react-icons/ai";
import "./tablaClientes.css";
import FormConfigTabla from "./FormConfigTabla";
import FiltroConfigTable from "./FiltroConfigTable";
import { getClientes } from "./fetchData/formCliente/getClientes";
import { getTablas } from "./fetchData/tablas/getTablas";
import FormClientes from "./FormClientes";
import { useAccess } from "../../../../hooks/useAccess";
import { accessDataFormat } from "../../../../helpers/access";
import LoadingIcon from "../../Loader/LoadingIcon";
import { PiAddressBookBold } from "react-icons/pi";
import { Tag } from "../../tag/Tag";
import LinkContactsToClient from "./LinkContactsToClient";


const DEFAULT_TABLE = [
  {
    _id: 1,
    nombreTabla: "Tabla por defecto",
    fixedOption: 4,
    columnas: [
      {
        "key": "4",
        "title": "Cuenta",
        "dataIndex": "idSistema",
        "index": 0,
        "hidden": false,
        "fixed": "",
        "tipo": "string",
        "width": "150px"
      },
      {
        "key": "1",
        "title": "Cliente",
        "dataIndex": "name",
        "index": 1,
        "hidden": false,
        "fixed": "",
        "tipo": "string"
      },
      {
        "key": "3",
        "title": "Teléfono",
        "dataIndex": "phone",
        "index": 2,
        "hidden": false,
        "fixed": "",
        "tipo": "string"
      },
      {
        "key": "2",
        "title": "Email",
        "dataIndex": "email",
        "index": 3,
        "hidden": false,
        "fixed": "",
        "tipo": "string"
      },
      {
        "key": "5",
        "title": "CUIT",
        "dataIndex": "cuit",
        "index": 4,
        "hidden": false,
        "fixed": "",
        "tipo": "string" 
      }
    ],
  },
];

export const TablaClientes = () => {
  const { clientes, setClientes, messageApi, setFiltros, instance } =
    useContext(GlobalContext);

  const isAllowed = useAccess();

  const [drawerConfigTabla, setDrawerConfigTabla] = useState(false);
  const [drawerFiltroTabla, setDrawerFiltroTabla] = useState(false);
  const [drawerFormCliente, setDrawerFormCliente] = useState(false);
  const [drawerLinkContacts, setDrawerLinkContacts] = useState(false);

  const [cliente, setCliente] = useState();
  const [tablaSelecc, setTablaSelecc] = useState();
  const [action, setAction] = useState();
  const [idTablaSelecc, setIdTablaSelecc] = useState(DEFAULT_TABLE[0].idTabla);
  const [refetchClientes, setRefetchClientes] = useState(false);

  const [loading, setLoading] = useState(true);
  const [newFilter, setNewFilter] = useState(false); // Indica si se ha creado, modificado o eliminado algun filtro.

  const [dataColumns, setDataColumns] = useState(DEFAULT_TABLE);



  useEffect(() => {
    if (instance?._id) {
      setLoading(true);
      getTablas(instance)
        .then((res) => {
          if (res?.status === 200 && Array.isArray(res.data)) {
            if (res.data.length > 0) {
              setDataColumns(res.data);
              setIdTablaSelecc(res.data[0]._id);
            }
          } else {
            messageApi.error(
              "Ocurrió un error al obtener las tablas (Clientes)"
            );
          }
        })
        .catch((err) => {
          messageApi.error("Ocurrió un error al obtener las tablas (Clientes)");
        })
        .finally(() => {
          setLoading(false);
          // setRefetchClientes(!refetchClientes);
          fetchClients();
        });
    }
  }, [instance]);


  const fetchClients = () => {
    setLoading(true);
    getClientes(instance)
      .then((res) => {
        // console.log('clientes API', res)
        if (res?.status === 200 && Array.isArray(res.data)) {
          setClientes(res.data);
        } else {
          messageApi.error("Ocurrió un error al obtener los clientes");
        }
      })
      .catch((err) => {
        messageApi.error("Ocurrió un error al obtener los clientes");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (instance?._id) {
      fetchClients();
    }
  }, [refetchClientes]);

  //Constante que se agrega al final de cada tabla.
  const columnaBotones = {
    title: "...",
    dataIndex: "",
    fixed: "right",
    width: 100,
    align: "center",
    key: "",
    render: (dataIndex, item) => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Editar">
            <span>
              <FiEdit3
                className="icono-acciones"
                onClick={() => {
                  //console.log("cliente seleccionado", item);
                  setCliente(item);
                  setDrawerFormCliente(true);
                }}
              />
            </span>
          </Tooltip>

          <Tooltip title="Contactos">
            <span>
              <PiAddressBookBold
                className="icono-acciones"
                onClick={() => {
                  //console.log("cliente seleccionado", item);
                  setCliente(item);
                  setDrawerLinkContacts(true);
                }}
              />
            </span>
          </Tooltip>
        </div>
      );
    },
  };

  const onChangeTab = (key) => {
    // console.log(key);
    setIdTablaSelecc(key);
  };


  const addRenderLEAD = dataColumns.map(tabla => {
    const nuevasColumnas = tabla.columnas.map(columna => {
      if (columna.dataIndex === 'idSistema') {
        return {
          ...columna,
          render: (text, record) => (
            record.idSistema === '' ? <Tag hex={'#77BE42'} nombre={'LEAD'} /> : text
          ),
        };
      }
      return columna;
    });

    return {
      ...tabla,
      columnas: nuevasColumnas,
    };
  });

  const columnasFull = addRenderLEAD?.map((columna, index) => {
    return {
      _id: columna._id,
      nombreTabla: columna.nombreTabla,
      columnas: isAllowed(accessDataFormat("clients", "manage"))
        ? [...columna.columnas, columnaBotones]
        : [...columna.columnas],
    };
  }); 


  const items = columnasFull?.map((columna, index) => {

    return {
      key: `${columna?._id}`,
      closable: false,
      label: `${columna?.nombreTabla}`,
      children: (
        <Table
          columns={
            columna?.columnas
              .filter((item) => !item.hidden)
              .sort((a, b) => a?.index - b?.index) || []
          }
          dataSource={
            clientes &&
            clientes.find((item) => item._id === columna?._id)?.clients
                    .filter(client => client.state === 1)
          }
          scroll={{
            x: 1300,
          }}
          size="small"
          pagination={{
            pageSize: 10,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} de ${total} clientes`,
          }}
          loading={{
            spinning: loading,
            indicator: <LoadingIcon size="large" />,
          }}
          className="tabla-tablaClientes"
        />
      ),
    };
  });

  const onClickFiltroTabla = () => {
    onEditTable();
    setDrawerFiltroTabla(true);
  };

  const onClickConfigColumnas = () => {
    setAction("editar");
    onEditTable();
    setDrawerConfigTabla(true);
  };

  const onEditTable = () => {
    const dataColumnEditar = dataColumns?.find(
      (element) => element._id === idTablaSelecc
    );
    const tablaSeleccClone = structuredClone(dataColumnEditar);
    setTablaSelecc(tablaSeleccClone);
  };

  const onEdit = (targetKey, action) => {
    //console.log('targetKey', targetKey)
    //console.log('action', action)

    if (action === "add") {
      setAction("crear");

      //Tabla generica a utilizar en la creacion de una nueva tabla
      const newTablaGenerica = structuredClone(dataColumns[0]);
      // newTablaGenerica._id = 0; //HARDCODEADO para que funcione ahora, en este caso va a ser preferible al hacer el post de la API, inmediatamente hacer otro que traiga el dataColumns actualizado. Otra posible solucion es que la API al realizar el POST, si devuelve 200, que me devuelva tambien el objeto creado y a eso hacerle el setState en el state 'dataColumns. Asi que esto va a dejar de tener importancia, solo sirve ahora para mostrar como anda.
      newTablaGenerica.nombreTabla = "";
      newTablaGenerica.fixedOption = 4;
      newTablaGenerica.columnas.forEach((element) => {
        element.hidden = true;
        element.fixed = "";
      });
      //console.log('newTablaGenerica', newTablaGenerica)

      setTablaSelecc(newTablaGenerica);
      setDrawerConfigTabla(true);
    }
  };

  //console.log('dataColumns Final', dataColumns);
  //console.log('cliente', cliente)
  //console.log('dataColumns', dataColumns)
  //console.log('clienteS', clientes)

  return (
    <div className="main-wrapper">
      <div className="main-header">
        <span>Clientes</span>
        {isAllowed(accessDataFormat("clients", "manage")) && (
                          //volver a poner esta clase como antes
           <div className="espacio-botones-tablaClientes"> 
            <Button
              type="primary"
              className="btn-agregar"
              onClick={() => {
                onClickConfigColumnas();
              }}
            >
              <AiOutlineSetting /> Configuración
            </Button>

            <Button
              type="primary"
              className="btn-agregar"
              onClick={() => {
                onClickFiltroTabla();
              }}
            >
              <AiOutlineSetting /> Filtros
            </Button>

            {/* <Button
              type="primary"
              className="btn-agregar"
              onClick={() => setDrawerFormCliente(true)}
            >
              <FiPlus /> Cliente
            </Button> */}
          </div>
        )}
        {/* Este boton agregar cliente se borrara, queda el comentado de arriba */}
        <Button
          type="primary"
          className="btn-agregar"
          onClick={() => setDrawerFormCliente(true)}
        >
          <FiPlus /> Cliente
        </Button>
      </div>
      <Tabs
        type="editable-card"
        activeKey={idTablaSelecc}
        items={items}
        onChange={onChangeTab}
        onEdit={onEdit}
        className="tabs-TablaClientes"
        //hideAdd={!isAllowed(accessDataFormat("clients", "manage"))}
        hideAdd={true}
      />

      {/* Drawer configuracion tablas */}
      <Drawer
        //title="Configuración de Tabla" //Esto puede variar con titulos de 'Nueva tabla' o 'Editar tabla'.
        open={drawerConfigTabla}
        onClose={() => setDrawerConfigTabla(false)}
        width={600}
        closeIcon={null}
        destroyOnClose={true}
        maskClosable={true}
        placement={"right"}
        styles={{ header: { display: "none" }, body: { padding: "0px" } }}
        // closable={true}
      >
        <FormConfigTabla
          editarTablaValues={tablaSelecc}
          setTablaSelecc={setTablaSelecc}
          dataColumns={dataColumns}
          setDataColumns={setDataColumns}
          idTablaSelecc={idTablaSelecc}
          setIdTablaSelecc={setIdTablaSelecc}
          setDrawerConfigTabla={setDrawerConfigTabla}
          action={action}
          setRefetchClientes={setRefetchClientes}
        />
      </Drawer>

      {/* Drawer filtro tablas */}
      <Drawer
        open={drawerFiltroTabla}
        onClose={() => {
          setDrawerFiltroTabla(false);
          setFiltros([]);
          if (newFilter === true) {
            setRefetchClientes(!refetchClientes);
            setNewFilter(false);
          }
        }}
        width={400}
        closeIcon={null}
        destroyOnClose={true}
        maskClosable={true}
        placement={"right"}
        styles={{ header: { display: "none" }, body: { padding: "0px" } }}
        // closable={true}
      >
        <FiltroConfigTable
          editarTablaValues={tablaSelecc}
          setNewFilter={setNewFilter}
        />
      </Drawer>

      {/* Drawer form clientes */}
      <Drawer
        open={drawerFormCliente}
        onClose={() => {
          setDrawerFormCliente(false);
          setCliente(undefined);
        }}
        closeIcon={null}
        destroyOnClose={true}
        placement="right"
        styles={{ header: { display: "none" }, body: { padding: "0px" } }}
        width={500}
      >
        <FormClientes
          editarClientesValues={cliente}
          setDrawerFormCliente={setDrawerFormCliente}
          setCliente={setCliente}
          idTablaSelecc={idTablaSelecc}
          setRefetchClientes={setRefetchClientes}
        />
      </Drawer>

      {/* Drawer contactos-cliente */}
      <Drawer
        open={drawerLinkContacts}
        onClose={() => {
          setDrawerLinkContacts(false);
          setCliente(undefined);
        }}
        closeIcon={null}
        destroyOnClose={true}
        placement="right"
        styles={{ header: { display: "none" }, body: { padding: "0px" } }}
        width={600}
      >
        <LinkContactsToClient 
          editarClientesValues={cliente}
          setDrawerLinkContacts={setDrawerLinkContacts}
          setCliente={setCliente}
          setRefetchClientes={setRefetchClientes}
        />
      </Drawer>
    </div>
  );
};
